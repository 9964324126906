import React, { useState, useEffect, useRef } from 'react';
import LogoGeneralitat from "../gencat-nuevo-completo-w.svg";
import { Dropdown, Menu } from 'semantic-ui-react';
import i18n from "../config/i18n";
import 'semantic-ui-css/semantic.min.css';

import { Trans } from "react-i18next";

export default function Header({
  inverted = true,
  content = <Trans i18nKey="loading" />,
}) {

  const [language, setLanguage] = useState(i18n.language  || '');

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage && storedLanguage !== language) {
      i18n.changeLanguage(storedLanguage);
      setLanguage(storedLanguage);
    }
  }, [language, i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    setLanguage(lng);
  };

  const dropdownRef = useRef(null);

  return (
    <Menu className='header no-select' inverted attached="top" stackable style={{ width: "100%" }}>
      <Menu.Item position="left">
        <img alt="gencat" src={LogoGeneralitat} style={{ width: 103 }} />
      </Menu.Item>
      <Menu.Item position="right" >
        <Dropdown
          ref={dropdownRef}
          item
          text={`${language.toUpperCase()}`}
          style={{ padding: '0 0px', color: "white" }}
        >
          <Dropdown.Menu style={{ minWidth: '3em' }}>
            <Dropdown.Item onClick={() => changeLanguage('ca')}>CA</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('es')}>ES</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('en')}>EN</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('oc')}>OCI</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>

    </Menu>
  );
}
