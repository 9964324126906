import React, { useContext } from "react";
import { Fade } from "react-awesome-reveal";

import { Trans } from "react-i18next";

import { format } from "date-fns";
import { DateTimeContext } from "../context/DateTimeContext";
import { Button, Segment } from "semantic-ui-react";
import { ServiceContext } from "../context/ServiceContext";

export default function FirstConfirmMessage({
  onHandleClickBack,
  onHandleClickNext,
}) {
  const [startDate] = useContext(DateTimeContext);
  const [serviceValues, setValues] = useContext(ServiceContext);
  const { category } = {
    category: serviceValues.value,
  };

  const getServiceText = () => {
    const service = window.location.pathname.split("/")[2];
    if(service == null){
      return (
        <>
          <strong>
            <Trans i18nKey="app.management" />
          </strong>
          {serviceValues.text}
          <br />
        </>
      )
    }
  }

  return (
    <>
      <Fade>
        <Segment style={{ marginBottom: "30px" }} textAlign="left" secondary>
          {getServiceText()}
          <strong>
            <Trans i18nKey="app.time_selected" />
          </strong>{" "}
          <span>{format(startDate, "dd-MM-yyyy")}</span>{" "}
          <strong>
            <Trans i18nKey="app.confirm_hour" />
          </strong>{" "}
          <span>
            {format(startDate, "HH:mm")}{" "}
            <Trans i18nKey="confirm.confirm_hour_2" />
          </span>
        </Segment>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "50px",
          }}
        >
          <Button
            color="red"
            basic
            content={<Trans i18nKey="confirm.btn_change_date" />}
            style={{ height: "50px" }}
            size="big"
            className="button"
            onClick={() => onHandleClickBack && onHandleClickBack()}
          ></Button>
          <Button
            content={<Trans i18nKey="first_confirm.btn_continue" />}
            size="big"
            negative
            icon="chevron right"
            labelPosition="right"
            onClick={() => {
              onHandleClickNext && onHandleClickNext();
            }}
          ></Button>
        </div>
      </Fade>
    </>
  );
}
