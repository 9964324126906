import { fetch } from "../util/FetchUtil";
import { EnumHttpMethod } from "../types/EnumHttpMethod";
import { getLanguageNavigator } from "../util/languages";

export const getServices = async (organization) => {
    if(!window.location.href.includes('cancel')){
        const endpoint = `${process.env.REACT_APP_API_URL}Services/${organization}?c=${getLanguageNavigator()}`;
      
        try {
            const response = await fetch(endpoint, EnumHttpMethod.GET);
            return response;
        } catch (error) {
            console.error(error);
        }
    }
}
