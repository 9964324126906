const flags = [
    {
       "name":"Afghanistan",
       "dial_code":"+93",
       "code":"AF",
       "flag":"🇦🇫",
       "translations":{
          "en":"Afghanistan",
          "es":"Afganistán",
          "ca":"Afganistan"
       }
    },
    {
       "name":"Albania",
       "dial_code":"+355",
       "code":"AL",
       "flag":"🇦🇱",
       "translations":{
          "en":"Albania",
          "es":"Albania",
          "ca":"Albània"
       }
    },
    {
       "name":"Algeria",
       "dial_code":"+213",
       "code":"DZ",
       "flag":"🇩🇿",
       "translations":{
          "en":"Algeria",
          "es":"Argelia",
          "ca":"Algèria"
       }
    },
    {
       "name":"AmericanSamoa",
       "dial_code":"+1684",
       "code":"AS",
       "flag":"🇦🇸",
       "translations":{
          "en":"American Samoa",
          "es":"Samoa Americana",
          "ca":"Samoa Americana"
       }
    },
    {
       "name":"Andorra",
       "dial_code":"+376",
       "code":"AD",
       "flag":"🇦🇩",
       "translations":{
          "en":"Andorra",
          "es":"Andorra",
          "ca":"Andorra"
       }
    },
    {
       "name":"Angola",
       "dial_code":"+244",
       "code":"AO",
       "flag":"🇦🇴",
       "translations":{
          "en":"Angola",
          "es":"Angola",
          "ca":"Angola"
       }
    },
    {
       "name":"Anguilla",
       "dial_code":"+1264",
       "code":"AI",
       "flag":"🇦🇮",
       "translations":{
          "en":"Anguilla",
          "es":"Anguila",
          "ca":"Anguilla"
       }
    },
    {
       "name":"Antarctica",
       "dial_code":"+672",
       "code":"AQ",
       "flag":"🇦🇶",
       "translations":{
          "en":"Antarctica",
          "es":"Antártida",
          "ca":"Antàrtida"
       }
    },
    {
       "name":"Antigua and Barbuda",
       "dial_code":"+1268",
       "code":"AG",
       "flag":"🇦🇬",
       "translations":{
          "en":"Antigua and Barbuda",
          "es":"Antigua y Barbuda",
          "ca":"Antigua i Barbuda"
       }
    },
    {
       "name":"Argentina",
       "dial_code":"+54",
       "code":"AR",
       "flag":"🇦🇷",
       "translations":{
          "en":"Argentina",
          "es":"Argentina",
          "ca":"Argentina"
       }
    },
    {
       "name":"Armenia",
       "dial_code":"+374",
       "code":"AM",
       "flag":"🇦🇲",
       "translations":{
          "en":"Armenia",
          "es":"Armenia",
          "ca":"Armènia"
       }
    },
    {
       "name":"Aruba",
       "dial_code":"+297",
       "code":"AW",
       "flag":"🇦🇼",
       "translations":{
          "en":"Aruba",
          "es":"Aruba",
          "ca":"Aruba"
       }
    },
    {
       "name":"Australia",
       "dial_code":"+61",
       "code":"AU",
       "preferred":true,
       "flag":"🇦🇺",
       "translations":{
          "en":"Australia",
          "es":"Australia",
          "ca":"Austràlia"
       }
    },
    {
       "name":"Austria",
       "dial_code":"+43",
       "code":"AT",
       "flag":"🇦🇹",
       "translations":{
          "en":"Austria",
          "es":"Austria",
          "ca":"Àustria"
       }
    },
    {
       "name":"Azerbaijan",
       "dial_code":"+994",
       "code":"AZ",
       "flag":"🇦🇿",
       "translations":{
          "en":"Azerbaijan",
          "es":"Azerbaiyán",
          "ca":"Azerbaidjan"
       }
    },
    {
       "name":"Bahamas",
       "dial_code":"+1242",
       "code":"BS",
       "flag":"🇧🇸",
       "translations":{
          "en":"Bahamas",
          "es":"Bahamas",
          "ca":"Bahames"
       }
    },
    {
       "name":"Bahrain",
       "dial_code":"+973",
       "code":"BH",
       "flag":"🇧🇭",
       "translations":{
          "en":"Bahrain",
          "es":"Bahréin",
          "ca":"Bahrain"
       }
    },
    {
       "name":"Bangladesh",
       "dial_code":"+880",
       "code":"BD",
       "flag":"🇧🇩",
       "translations":{
          "en":"Bangladesh",
          "es":"Bangladesh",
          "ca":"Bangla Desh"
       }
    },
    {
       "name":"Barbados",
       "dial_code":"+1246",
       "code":"BB",
       "flag":"🇧🇧",
       "translations":{
          "en":"Barbados",
          "es":"Barbados",
          "ca":"Barbados"
       }
    },
    {
       "name":"Belarus",
       "dial_code":"+375",
       "code":"BY",
       "flag":"🇧🇾",
       "translations":{
          "en":"Belarus",
          "es":"Bielorrusia",
          "ca":"Bielorússia"
       }
    },
    {
       "name":"Belgium",
       "dial_code":"+32",
       "code":"BE",
       "flag":"🇧🇪",
       "translations":{
          "en":"Belgium",
          "es":"Bélgica",
          "ca":"Bèlgica"
       }
    },
    {
       "name":"Belize",
       "dial_code":"+501",
       "code":"BZ",
       "flag":"🇧🇿",
       "translations":{
          "en":"Belize",
          "es":"Belice",
          "ca":"Belize"
       }
    },
    {
       "name":"Benin",
       "dial_code":"+229",
       "code":"BJ",
       "flag":"🇧🇯",
       "translations":{
          "en":"Benin",
          "es":"Benín",
          "ca":"Benín"
       }
    },
    {
       "name":"Bermuda",
       "dial_code":"+1441",
       "code":"BM",
       "flag":"🇧🇲",
       "translations":{
          "en":"Bermuda",
          "es":"Bermudas",
          "ca":"Bermudes"
       }
    },
    {
       "name":"Bhutan",
       "dial_code":"+975",
       "code":"BT",
       "flag":"🇧🇹",
       "translations":{
          "en":"Bhutan",
          "es":"Bután",
          "ca":"Bhutan"
       }
    },
    {
       "name":"Bolivia, Plurinational State of",
       "dial_code":"+591",
       "code":"BO",
       "flag":"🇧🇴",
       "translations":{
          "en":"Bolivia",
          "es":"Bolivia",
          "ca":"Bolívia"
       }
    },
    {
       "name":"Bosnia and Herzegovina",
       "dial_code":"+387",
       "code":"BA",
       "flag":"🇧🇦",
       "translations":{
          "en":"Bosnia and Herzegovina",
          "es":"Bosnia y Herzegovina",
          "ca":"Bòsnia i Hercegovina"
       }
    },
    {
       "name":"Botswana",
       "dial_code":"+267",
       "code":"BW",
       "flag":"🇧🇼",
       "translations":{
          "en":"Botswana",
          "es":"Botsuana",
          "ca":"Botswana"
       }
    },
    {
       "name":"Brazil",
       "dial_code":"+55",
       "code":"BR",
       "flag":"🇧🇷",
       "translations":{
          "en":"Brazil",
          "es":"Brasil",
          "ca":"Brasil"
       }
    },
    {
       "name":"British Indian Ocean Territory",
       "dial_code":"+246",
       "code":"IO",
       "flag":"🇮🇴",
       "translations":{
          "en":"British Indian Ocean Territory",
          "es":"Territorio Británico del Océano Índico",
          "ca":"Territori Britànic de l\\'Oceà Índic"
       }
    },
    {
       "name":"Brunei Darussalam",
       "dial_code":"+673",
       "code":"BN",
       "flag":"🇧🇳",
       "translations":{
          "en":"Brunei",
          "es":"Brunei",
          "ca":"Brunei"
       }
    },
    {
       "name":"Bulgaria",
       "dial_code":"+359",
       "code":"BG",
       "flag":"🇧🇬",
       "translations":{
          "en":"Bulgaria",
          "es":"Bulgaria",
          "ca":"Bulgària"
       }
    },
    {
       "name":"Burkina Faso",
       "dial_code":"+226",
       "code":"BF",
       "flag":"🇧🇫",
       "translations":{
          "en":"Burkina Faso",
          "es":"Burkina Faso",
          "ca":"Burkina Faso"
       }
    },
    {
       "name":"Burundi",
       "dial_code":"+257",
       "code":"BI",
       "flag":"🇧🇮",
       "translations":{
          "en":"Burundi",
          "es":"Burundi",
          "ca":"Burundi"
       }
    },
    {
       "name":"Cambodia",
       "dial_code":"+855",
       "code":"KH",
       "flag":"🇰🇭",
       "translations":{
          "en":"Cambodia",
          "es":"Camboya",
          "ca":"Cambodja"
       }
    },
    {
       "name":"Cameroon",
       "dial_code":"+237",
       "code":"CM",
       "flag":"🇨🇲",
       "translations":{
          "en":"Cameroon",
          "es":"Camerún",
          "ca":"Camerun"
       }
    },
    /*{
       "name":"Canada",
       "dial_code":"+1",
       "code":"CA",
       "flag":"🇨🇦",
       "translations":{
          "en":"Canada",
          "es":"Canadá",
          "ca":"Canadà"
       }
    },*/
    {
       "name":"Cape Verde",
       "dial_code":"+238",
       "code":"CV",
       "flag":"🇨🇻",
       "translations":{
          "en":"Cape Verde",
          "es":"Cabo Verde",
          "ca":"Cap Verd"
       }
    },
    {
       "name":"Cayman Islands",
       "dial_code":"+345",
       "code":"KY",
       "flag":"🇰🇾",
       "translations":{
          "en":"Cayman Islands",
          "es":"Islas Caimán",
          "ca":"Illes Caiman"
       }
    },
    {
       "name":"Central African Republic",
       "dial_code":"+236",
       "code":"CF",
       "flag":"🇨🇫",
       "translations":{
          "en":"Central African Republic",
          "es":"República Centroafricana",
          "ca":"República Centreafricana"
       }
    },
    {
       "name":"Chad",
       "dial_code":"+235",
       "code":"TD",
       "flag":"🇹🇩",
       "translations":{
          "en":"Chad",
          "es":"Chad",
          "ca":"Txad"
       }
    },
    {
       "name":"Chile",
       "dial_code":"+56",
       "code":"CL",
       "flag":"🇨🇱",
       "translations":{
          "en":"Chile",
          "es":"Chile",
          "ca":"Xile"
       }
    },
    {
       "name":"China",
       "dial_code":"+86",
       "code":"CN",
       "flag":"🇨🇳",
       "translations":{
          "en":"China",
          "es":"China",
          "ca":"Xina"
       }
    },
    {
       "name":"Christmas Island",
       "dial_code":"+61",
       "code":"CX",
       "flag":"🇨🇽",
       "translations":{
          "en":"Christmas Island",
          "es":"Isla de Navidad",
          "ca":"Illa de Nadal"
       }
    },
    {
       "name":"Cocos (Keeling) Islands",
       "dial_code":"+61",
       "code":"CC",
       "flag":"🇨🇨",
       "translations":{
          "en":"Cocos (Keeling) Islands",
          "es":"Islas Cocos",
          "ca":"Illes Cocos"
       }
    },
    {
       "name":"Colombia",
       "dial_code":"+57",
       "code":"CO",
       "flag":"🇨🇴",
       "translations":{
          "en":"Colombia",
          "es":"Colombia",
          "ca":"Colòmbia"
       }
    },
    {
       "name":"Comoros",
       "dial_code":"+269",
       "code":"KM",
       "flag":"🇰🇲",
       "translations":{
          "en":"Comoros",
          "es":"Comoras",
          "ca":"Comores"
       }
    },
    {
       "name":"Congo",
       "dial_code":"+242",
       "code":"CG",
       "flag":"🇨🇬",
       "translations":{
          "en":"Congo",
          "es":"Congo",
          "ca":"Congo"
       }
    },
    {
       "name":"Congo, The Democratic Republic of the",
       "dial_code":"+243",
       "code":"CD",
       "flag":"🇨🇩",
       "translations":{
          "en":"Congo, The Democratic Republic of the",
          "es":"Congo, República Democrática del",
          "ca":"Congo, República Democràtica del"
       }
    },
    {
       "name":"Cook Islands",
       "dial_code":"+682",
       "code":"CK",
       "flag":"🇨🇰",
       "translations":{
          "en":"Cook Islands",
          "es":"Islas Cook",
          "ca":"Illes Cook"
       }
    },
    {
       "name":"Costa Rica",
       "dial_code":"+506",
       "code":"CR",
       "flag":"🇨🇷",
       "translations":{
          "en":"Costa Rica",
          "es":"Costa Rica",
          "ca":"Costa Rica"
       }
    },
    {
       "name":"Cote d'Ivoire",
       "dial_code":"+225",
       "code":"CI",
       "flag":"🇨🇮",
       "translations":{
          "en":"Cote d'Ivoire",
          "es":"Costa de Marfil",
          "ca":"Costa d'Ivori"
       }
    },
    {
       "name":"Croatia",
       "dial_code":"+385",
       "code":"HR",
       "flag":"🇭🇷",
       "translations":{
          "en":"Croatia",
          "es":"Croacia",
          "ca":"Croàcia"
       }
    },
    {
       "name":"Cuba",
       "dial_code":"+53",
       "code":"CU",
       "flag":"🇨🇺",
       "translations":{
          "en":"Cuba",
          "es":"Cuba",
          "ca":"Cuba"
       }
    },
    {
       "name":"Cyprus",
       "dial_code":"+357",
       "code":"CY",
       "flag":"🇨🇾",
       "translations":{
          "en":"Cyprus",
          "es":"Chipre",
          "ca":"Xipre"
       }
    },
    {
       "name":"Czech Republic",
       "dial_code":"+420",
       "code":"CZ",
       "flag":"🇨🇿",
       "translations":{
          "en":"Czech Republic",
          "es":"República Checa",
          "ca":"República Txeca"
       }
    },
    {
       "name":"Denmark",
       "dial_code":"+45",
       "code":"DK",
       "flag":"🇩🇰",
       "translations":{
          "en":"Denmark",
          "es":"Dinamarca",
          "ca":"Dinamarca"
       }
    },
    {
       "name":"Djibouti",
       "dial_code":"+253",
       "code":"DJ",
       "flag":"🇩🇯",
       "translations":{
          "en":"Djibouti",
          "es":"Yibuti",
          "ca":"Djibouti"
       }
    },
    {
       "name":"Dominica",
       "dial_code":"+1767",
       "code":"DM",
       "flag":"🇩🇲",
       "translations":{
          "en":"Dominica",
          "es":"Dominica",
          "ca":"Dominica"
       }
    },
    {
       "name":"Dominican Republic",
       "dial_code":"+1849",
       "code":"DO",
       "flag":"🇩🇴",
       "translations":{
          "en":"Dominican Republic",
          "es":"República Dominicana",
          "ca":"República Dominicana"
       }
    },
    {
       "name":"Ecuador",
       "dial_code":"+593",
       "code":"EC",
       "flag":"🇪🇨",
       "translations":{
          "en":"Ecuador",
          "es":"Ecuador",
          "ca":"Equador"
       }
    },
    {
       "name":"Egypt",
       "dial_code":"+20",
       "code":"EG",
       "flag":"🇪🇬",
       "translations":{
          "en":"Egypt",
          "es":"Egipto",
          "ca":"Egipte"
       }
    },
    {
       "name":"El Salvador",
       "dial_code":"+503",
       "code":"SV",
       "flag":"🇸🇻",
       "translations":{
          "en":"El Salvador",
          "es":"El Salvador",
          "ca":"El Salvador"
       }
    },
    {
       "name":"Equatorial Guinea",
       "dial_code":"+240",
       "code":"GQ",
       "flag":"🇬🇶",
       "translations":{
          "en":"Equatorial Guinea",
          "es":"Guinea Ecuatorial",
          "ca":"Guinea Equatorial"
       }
    },
    {
       "name":"Eritrea",
       "dial_code":"+291",
       "code":"ER",
       "flag":"🇪🇷",
       "translations":{
          "en":"Eritrea",
          "es":"Eritrea",
          "ca":"Eritrea"
       }
    },
    {
       "name":"Estonia",
       "dial_code":"+372",
       "code":"EE",
       "flag":"🇪🇪",
       "translations":{
          "en":"Estonia",
          "es":"Estonia",
          "ca":"Estònia"
       }
    },
    {
       "name":"Ethiopia",
       "dial_code":"+251",
       "code":"ET",
       "flag":"🇪🇹",
       "translations":{
          "en":"Ethiopia",
          "es":"Etiopía",
          "ca":"Etiòpia"
       }
    },
    {
       "name":"Falkland Islands (Malvinas)",
       "dial_code":"+500",
       "code":"FK",
       "flag":"🇫🇰",
       "translations":{
          "en":"Falkland Islands (Malvinas)",
          "es":"Islas Malvinas (Falkland)",
          "ca":"Illes Malvines (Falkland)"
       }
    },
    {
       "name":"Faroe Islands",
       "dial_code":"+298",
       "code":"FO",
       "flag":"🇫🇴",
       "translations":{
          "en":"Faroe Islands",
          "es":"Islas Feroe",
          "ca":"Illes Fèroe"
       }
    },
    {
       "name":"Fiji",
       "dial_code":"+679",
       "code":"FJ",
       "flag":"🇫🇯",
       "translations":{
          "en":"Fiji",
          "es":"Fiyi",
          "ca":"Fiji"
       }
    },
    {
       "name":"Finland",
       "dial_code":"+358",
       "code":"FI",
       "flag":"🇫🇮",
       "translations":{
          "en":"Finland",
          "es":"Finlandia",
          "ca":"Finlàndia"
       }
    },
    {
       "name":"France",
       "dial_code":"+33",
       "code":"FR",
       "flag":"🇫🇷",
       "translations":{
          "en":"France",
          "es":"Francia",
          "ca":"França"
       }
    },
    {
       "name":"French Guiana",
       "dial_code":"+594",
       "code":"GF",
       "flag":"🇬🇫",
       "translations":{
          "en":"French Guiana",
          "es":"Guayana Francesa",
          "ca":"Guaiana Francesa"
       }
    },
    {
       "name":"French Polynesia",
       "dial_code":"+689",
       "code":"PF",
       "flag":"🇵🇫",
       "translations":{
          "en":"French Polynesia",
          "es":"Polinesia Francesa",
          "ca":"Polinèsia Francesa"
       }
    },
    {
       "name":"Gabon",
       "dial_code":"+241",
       "code":"GA",
       "flag":"🇬🇦",
       "translations":{
          "en":"Gabon",
          "es":"Gabón",
          "ca":"Gabon"
       }
    },
    {
       "name":"Gambia",
       "dial_code":"+220",
       "code":"GM",
       "flag":"🇬🇲",
       "translations":{
          "en":"Gambia",
          "es":"Gambia",
          "ca":"Gàmbia"
       }
    },
    {
       "name":"Georgia",
       "dial_code":"+995",
       "code":"GE",
       "flag":"🇬🇪",
       "translations":{
          "en":"Georgia",
          "es":"Georgia",
          "ca":"Geòrgia"
       }
    },
    {
       "name":"Alemania",
       "dial_code":"+49",
       "code":"DE",
       "flag":"🇩🇪",
       "translations":{
          "en":"Germany",
          "es":"Alemania",
          "ca":"Alemanya"
       }
    },
    {
       "name":"Ghana",
       "dial_code":"+233",
       "code":"GH",
       "flag":"🇬🇭",
       "translations":{
          "en":"Ghana",
          "es":"Ghana",
          "ca":"Ghana"
       }
    },
    {
       "name":"Gibraltar",
       "dial_code":"+350",
       "code":"GI",
       "flag":"🇬🇮",
       "translations":{
          "en":"Gibraltar",
          "es":"Gibraltar",
          "ca":"Gibraltar"
       }
    },
    {
       "name":"Greece",
       "dial_code":"+30",
       "code":"GR",
       "flag":"🇬🇷",
       "translations":{
          "en":"Greece",
          "es":"Grecia",
          "ca":"Grècia"
       }
    },
    {
       "name":"Greenland",
       "dial_code":"+299",
       "code":"GL",
       "flag":"🇬🇱",
       "translations":{
          "en":"Greenland",
          "es":"Groenlandia",
          "ca":"Grenlàndia"
       }
    },
    {
       "name":"Grenada",
       "dial_code":"+1473",
       "code":"GD",
       "flag":"🇬🇩",
       "translations":{
          "en":"Grenada",
          "es":"Granada",
          "ca":"Grenada"
       }
    },
    {
       "name":"Guadeloupe",
       "dial_code":"+590",
       "code":"GP",
       "flag":"🇬🇵",
       "translations":{
          "en":"Guadeloupe",
          "es":"Guadalupe",
          "ca":"Guadalupe"
       }
    },
    {
       "name":"Guam",
       "dial_code":"+1671",
       "code":"GU",
       "flag":"🇬🇺",
       "translations":{
          "en":"Guam",
          "es":"Guam",
          "ca":"Guam"
       }
    },
    {
       "name":"Guatemala",
       "dial_code":"+502",
       "code":"GT",
       "flag":"🇬🇹",
       "translations":{
          "en":"Guatemala",
          "es":"Guatemala",
          "ca":"Guatemala"
       }
    },
    {
       "name":"Guernsey",
       "dial_code":"+44",
       "code":"GG",
       "flag":"🇬🇬",
       "translations":{
          "en":"Guernsey",
          "es":"Guernsey",
          "ca":"Guernsey"
       }
    },
    {
       "name":"Guinea",
       "dial_code":"+224",
       "code":"GN",
       "flag":"🇬🇳",
       "translations":{
          "en":"Guinea",
          "es":"Guinea",
          "ca":"Guinea"
       }
    },
    {
       "name":"Guinea-Bissau",
       "dial_code":"+245",
       "code":"GW",
       "flag":"🇬🇼",
       "translations":{
          "en":"Guinea-Bissau",
          "es":"Guinea-Bisáu",
          "ca":"Guinea Bissau"
       }
    },
    {
       "name":"Guyana",
       "dial_code":"+592",
       "code":"GY",
       "flag":"🇬🇾",
       "translations":{
          "en":"Guyana",
          "es":"Guyana",
          "ca":"Guyana"
       }
    },
    {
       "name":"Haiti",
       "dial_code":"+509",
       "code":"HT",
       "flag":"🇭🇹",
       "translations":{
          "en":"Haiti",
          "es":"Haití",
          "ca":"Haití"
       }
    },
    {
       "name":"Holy See (Vatican City State)",
       "dial_code":"+379",
       "code":"VA",
       "flag":"🇻🇦",
       "translations":{
          "en":"Holy See (Vatican City State)",
          "es":"Ciudad del Vaticano",
          "ca":"Ciutat del Vaticà"
       }
    },
    {
       "name":"Honduras",
       "dial_code":"+504",
       "code":"HN",
       "flag":"🇭🇳",
       "translations":{
          "en":"Honduras",
          "es":"Honduras",
          "ca":"Hondures"
       }
    },
    {
       "name":"Hong Kong",
       "dial_code":"+852",
       "code":"HK",
       "flag":"🇭🇰",
       "translations":{
          "en":"Hong Kong",
          "es":"Hong Kong",
          "ca":"Hong Kong"
       }
    },
    {
       "name":"Hungary",
       "dial_code":"+36",
       "code":"HU",
       "flag":"🇭🇺",
       "translations":{
          "en":"Hungary",
          "es":"Hungría",
          "ca":"Hongria"
       }
    },
    {
       "name":"Iceland",
       "dial_code":"+354",
       "code":"IS",
       "flag":"🇮🇸",
       "translations":{
          "en":"Iceland",
          "es":"Islandia",
          "ca":"Islàndia"
       }
    },
    {
       "name":"India",
       "dial_code":"+91",
       "code":"IN",
       "preferred":true,
       "flag":"🇮🇳",
       "translations":{
          "en":"India",
          "es":"India",
          "ca":"Índia"
       }
    },
    {
       "name":"Indonesia",
       "dial_code":"+62",
       "code":"ID",
       "flag":"🇮🇩",
       "translations":{
          "en":"Indonesia",
          "es":"Indonesia",
          "ca":"Indonèsia"
       }
    },
    {
       "name":"Iran, Islamic Republic of",
       "dial_code":"+98",
       "code":"IR",
       "flag":"🇮🇷",
       "translations":{
          "en":"Iran, Islamic Republic of",
          "es":"Irán",
          "ca":"Iran"
       }
    },
    {
       "name":"Iraq",
       "dial_code":"+964",
       "code":"IQ",
       "flag":"🇮🇶",
       "translations":{
          "en":"Iraq",
          "es":"Irak",
          "ca":"Iraq"
       }
    },
    {
       "name":"Ireland",
       "dial_code":"+353",
       "code":"IE",
       "flag":"🇮🇪",
       "translations":{
          "en":"Ireland",
          "es":"Irlanda",
          "ca":"Irlanda"
       }
    },
    {
       "name":"Isle of Man",
       "dial_code":"+44",
       "code":"IM",
       "flag":"🇮🇲",
       "translations":{
          "en":"Isle of Man",
          "es":"Isla de Man",
          "ca":"Illa de Man"
       }
    },
    {
       "name":"Israel",
       "dial_code":"+972",
       "code":"IL",
       "flag":"🇮🇱",
       "translations":{
          "en":"Israel",
          "es":"Israel",
          "ca":"Israel"
       }
    },
    {
       "name":"Italy",
       "dial_code":"+39",
       "code":"IT",
       "flag":"🇮🇹",
       "translations":{
          "en":"Italy",
          "es":"Italia",
          "ca":"Itàlia"
       }
    },
    {
       "name":"Jamaica",
       "dial_code":"+1876",
       "code":"JM",
       "flag":"🇯🇲",
       "translations":{
          "en":"Jamaica",
          "es":"Jamaica",
          "ca":"Jamaica"
       }
    },
    {
       "name":"Japan",
       "dial_code":"+81",
       "code":"JP",
       "flag":"🇯🇵",
       "translations":{
          "en":"Japan",
          "es":"Japón",
          "ca":"Japó"
       }
    },
    {
       "name":"Jersey",
       "dial_code":"+44",
       "code":"JE",
       "flag":"🇯🇪",
       "translations":{
          "en":"Jersey",
          "es":"Jersey",
          "ca":"Jersey"
       }
    },
    {
       "name":"Jordan",
       "dial_code":"+962",
       "code":"JO",
       "flag":"🇯🇴",
       "translations":{
          "en":"Jordan",
          "es":"Jordania",
          "ca":"Jordània"
       }
    },
    {
       "name":"Kazakhstan",
       "dial_code":"+77",
       "code":"KZ",
       "flag":"🇰🇿",
       "translations":{
          "en":"Kazakhstan",
          "es":"Kazajistán",
          "ca":"Kazakhstan"
       }
    },
    {
       "name":"Kenya",
       "dial_code":"+254",
       "code":"KE",
       "flag":"🇰🇪",
       "translations":{
          "en":"Kenya",
          "es":"Kenia",
          "ca":"Kenya"
       }
    },
    {
       "name":"Kiribati",
       "dial_code":"+686",
       "code":"KI",
       "flag":"🇰🇮",
       "translations":{
          "en":"Kiribati",
          "es":"Kiribati",
          "ca":"Kiribati"
       }
    },
    {
       "name":"Korea, Democratic People's Republic of",
       "dial_code":"+850",
       "code":"KP",
       "flag":"🇰🇵",
       "translations":{
          "en":"Korea, Democratic People's Republic of",
          "es":"Corea del Norte",
          "ca":"Corea del Nord"
       }
    },
    {
       "name":"Korea, Republic of",
       "dial_code":"+82",
       "code":"KR",
       "flag":"🇰🇷",
       "translations":{
          "en":"Korea, Republic of",
          "es":"Corea del Sur",
          "ca":"Corea del Sud"
       }
    },
    {
       "name":"Kuwait",
       "dial_code":"+965",
       "code":"KW",
       "flag":"🇰🇼",
       "translations":{
          "en":"Kuwait",
          "es":"Kuwait",
          "ca":"Kuwait"
       }
    },
    {
       "name":"Kyrgyzstan",
       "dial_code":"+996",
       "code":"KG",
       "flag":"🇰🇬",
       "translations":{
          "en":"Kyrgyzstan",
          "es":"Kirguistán",
          "ca":"Kirguizistan"
       }
    },
    {
       "name":"Lao People's Democratic Republic",
       "dial_code":"+856",
       "code":"LA",
       "flag":"🇱🇦",
       "translations":{
          "en":"Lao People's Democratic Republic",
          "es":"Laos",
          "ca":"Laos"
       }
    },
    {
       "name":"Latvia",
       "dial_code":"+371",
       "code":"LV",
       "flag":"🇱🇻",
       "translations":{
          "en":"Latvia",
          "es":"Letonia",
          "ca":"Letònia"
       }
    },
    {
       "name":"Lebanon",
       "dial_code":"+961",
       "code":"LB",
       "flag":"🇱🇧",
       "translations":{
          "en":"Lebanon",
          "es":"Líbano",
          "ca":"Líban"
       }
    },
    {
       "name":"Lesotho",
       "dial_code":"+266",
       "code":"LS",
       "flag":"🇱🇸",
       "translations":{
          "en":"Lesotho",
          "es":"Lesoto",
          "ca":"Lesotho"
       }
    },
    {
       "name":"Liberia",
       "dial_code":"+231",
       "code":"LR",
       "flag":"🇱🇷",
       "translations":{
          "en":"Liberia",
          "es":"Liberia",
          "ca":"Libèria"
       }
    },
    {
       "name":"Libyan Arab Jamahiriya",
       "dial_code":"+218",
       "code":"LY",
       "flag":"🇱🇾",
       "translations":{
          "en":"Libya",
          "es":"Libia",
          "ca":"Líbia"
       }
    },
    {
       "name":"Liechtenstein",
       "dial_code":"+423",
       "code":"LI",
       "flag":"🇱🇮",
       "translations":{
          "en":"Liechtenstein",
          "es":"Liechtenstein",
          "ca":"Liechtenstein"
       }
    },
    {
       "name":"Lithuania",
       "dial_code":"+370",
       "code":"LT",
       "flag":"🇱🇹",
       "translations":{
          "en":"Lithuania",
          "es":"Lituania",
          "ca":"Lituània"
       }
    },
    {
       "name":"Luxembourg",
       "dial_code":"+352",
       "code":"LU",
       "flag":"🇱🇺",
       "translations":{
          "en":"Luxembourg",
          "es":"Luxemburgo",
          "ca":"Luxemburg"
       }
    },
    {
       "name":"Macao",
       "dial_code":"+853",
       "code":"MO",
       "flag":"🇲🇴",
       "translations":{
          "en":"Macao",
          "es":"Macao",
          "ca":"Macau"
       }
    },
    {
       "name":"Macedonia, The Former Yugoslav Republic of",
       "dial_code":"+389",
       "code":"MK",
       "flag":"🇲🇰",
       "translations":{
          "en":"North Macedonia",
          "es":"Macedonia del Norte",
          "ca":"Macedònia del Nord"
       }
    },
    {
       "name":"Madagascar",
       "dial_code":"+261",
       "code":"MG",
       "flag":"🇲🇬",
       "translations":{
          "en":"Madagascar",
          "es":"Madagascar",
          "ca":"Madagascar"
       }
    },
    {
       "name":"Malawi",
       "dial_code":"+265",
       "code":"MW",
       "flag":"🇲🇼",
       "translations":{
          "en":"Malawi",
          "es":"Malawi",
          "ca":"Malawi"
       }
    },
    {
       "name":"Malaysia",
       "dial_code":"+60",
       "code":"MY",
       "flag":"🇲🇾",
       "translations":{
          "en":"Malaysia",
          "es":"Malasia",
          "ca":"Malàisia"
       }
    },
    {
       "name":"Maldives",
       "dial_code":"+960",
       "code":"MV",
       "flag":"🇲🇻",
       "translations":{
          "en":"Maldives",
          "es":"Maldivas",
          "ca":"Maldives"
       }
    },
    {
       "name":"Mali",
       "dial_code":"+223",
       "code":"ML",
       "flag":"🇲🇱",
       "translations":{
          "en":"Mali",
          "es":"Mali",
          "ca":"Mali"
       }
    },
    {
       "name":"Malta",
       "dial_code":"+356",
       "code":"MT",
       "flag":"🇲🇹",
       "translations":{
          "en":"Malta",
          "es":"Malta",
          "ca":"Malta"
       }
    },
    {
       "name":"Marshall Islands",
       "dial_code":"+692",
       "code":"MH",
       "flag":"🇲🇭",
       "translations":{
          "en":"Marshall Islands",
          "es":"Islas Marshall",
          "ca":"Illes Marshall"
       }
    },
    {
       "name":"Martinique",
       "dial_code":"+596",
       "code":"MQ",
       "flag":"🇲🇶",
       "translations":{
          "en":"Martinique",
          "es":"Martinica",
          "ca":"Martinica"
       }
    },
    {
       "name":"Mauritania",
       "dial_code":"+222",
       "code":"MR",
       "flag":"🇲🇷",
       "translations":{
          "en":"Mauritania",
          "es":"Mauritania",
          "ca":"Mauritània"
       }
    },
    {
       "name":"Mauritius",
       "dial_code":"+230",
       "code":"MU",
       "flag":"🇲🇺",
       "translations":{
          "en":"Mauritius",
          "es":"Mauricio",
          "ca":"Maurici"
       }
    },
    {
       "name":"Mayotte",
       "dial_code":"+262",
       "code":"YT",
       "flag":"🇾🇹",
       "translations":{
          "en":"Mayotte",
          "es":"Mayotte",
          "ca":"Mayotte"
       }
    },
    {
       "name":"Mexico",
       "dial_code":"+52",
       "code":"MX",
       "flag":"🇲🇽",
       "translations":{
          "en":"Mexico",
          "es":"México",
          "ca":"Mèxic"
       }
    },
    {
       "name":"Micronesia, Federated States of",
       "dial_code":"+691",
       "code":"FM",
       "flag":"🇫🇲",
       "translations":{
          "en":"Micronesia, Federated States of",
          "es":"Micronesia, Estados Federados de",
          "ca":"Micronèsia, Estats Federats de"
       }
    },
    {
       "name":"Moldova, Republic of",
       "dial_code":"+373",
       "code":"MD",
       "flag":"🇲🇩",
       "translations":{
          "en":"Moldova, Republic of",
          "es":"Moldavia, República de",
          "ca":"Moldàvia, República de"
       }
    },
    {
       "name":"Monaco",
       "dial_code":"+377",
       "code":"MC",
       "flag":"🇲🇨",
       "translations":{
          "en":"Monaco",
          "es":"Mónaco",
          "ca":"Mònaco"
       }
    },
    {
       "name":"Mongolia",
       "dial_code":"+976",
       "code":"MN",
       "flag":"🇲🇳",
       "translations":{
          "en":"Mongolia",
          "es":"Mongolia",
          "ca":"Mongòlia"
       }
    },
    {
       "name":"Montenegro",
       "dial_code":"+382",
       "code":"ME",
       "flag":"🇲🇪",
       "translations":{
          "en":"Montenegro",
          "es":"Montenegro",
          "ca":"Montenegro"
       }
    },
    {
       "name":"Montserrat",
       "dial_code":"+1664",
       "code":"MS",
       "flag":"🇲🇸",
       "translations":{
          "en":"Montserrat",
          "es":"Montserrat",
          "ca":"Montserrat"
       }
    },
    {
       "name":"Morocco",
       "dial_code":"+212",
       "code":"MA",
       "flag":"🇲🇦",
       "translations":{
          "en":"Morocco",
          "es":"Marruecos",
          "ca":"Marroc"
       }
    },
    {
       "name":"Mozambique",
       "dial_code":"+258",
       "code":"MZ",
       "flag":"🇲🇿",
       "translations":{
          "en":"Mozambique",
          "es":"Mozambique",
          "ca":"Moçambic"
       }
    },
    {
       "name":"Myanmar",
       "dial_code":"+95",
       "code":"MM",
       "flag":"🇲🇲",
       "translations":{
          "en":"Myanmar",
          "es":"Myanmar",
          "ca":"Myanmar (Birmània)"
       }
    },
    {
       "name":"Namibia",
       "dial_code":"+264",
       "code":"NA",
       "flag":"🇳🇦",
       "translations":{
          "en":"Namibia",
          "es":"Namibia",
          "ca":"Namíbia"
       }
    },
    {
       "name":"Nauru",
       "dial_code":"+674",
       "code":"NR",
       "flag":"🇳🇷",
       "translations":{
          "en":"Nauru",
          "es":"Nauru",
          "ca":"Nauru"
       }
    },
    {
       "name":"Nepal",
       "dial_code":"+977",
       "code":"NP",
       "flag":"🇳🇵",
       "translations":{
          "en":"Nepal",
          "es":"Nepal",
          "ca":"Nepal"
       }
    },
    {
       "name":"Netherlands",
       "dial_code":"+31",
       "code":"NL",
       "flag":"🇳🇱",
       "translations":{
          "en":"Netherlands",
          "es":"Países Bajos",
          "ca":"Països Baixos"
       }
    },
    {
       "name":"Netherlands Antilles",
       "dial_code":"+599",
       "code":"AN",
       "flag":"🇦🇳",
       "translations":{
          "en":"Netherlands Antilles",
          "es":"Antillas Neerlandesas",
          "ca":"Antilles Neerlandeses"
       }
    },
    {
       "name":"New Caledonia",
       "dial_code":"+687",
       "code":"NC",
       "flag":"🇳🇨",
       "translations":{
          "en":"New Caledonia",
          "es":"Nueva Caledonia",
          "ca":"Nova Caledònia"
       }
    },
    {
       "name":"New Zealand",
       "dial_code":"+64",
       "code":"NZ",
       "flag":"🇳🇿",
       "translations":{
          "en":"New Zealand",
          "es":"Nueva Zelanda",
          "ca":"Nova Zelanda"
       }
    },
    {
       "name":"Nicaragua",
       "dial_code":"+505",
       "code":"NI",
       "flag":"🇳🇮",
       "translations":{
          "en":"Nicaragua",
          "es":"Nicaragua",
          "ca":"Nicaragua"
       }
    },
    {
       "name":"Niger",
       "dial_code":"+227",
       "code":"NE",
       "flag":"🇳🇪",
       "translations":{
          "en":"Niger",
          "es":"Níger",
          "ca":"Níger"
       }
    },
    {
       "name":"Nigeria",
       "dial_code":"+234",
       "code":"NG",
       "flag":"🇳🇬",
       "translations":{
          "en":"Nigeria",
          "es":"Nigeria",
          "ca":"Nigèria"
       }
    },
    {
       "name":"Niue",
       "dial_code":"+683",
       "code":"NU",
       "flag":"🇳🇺",
       "translations":{
          "en":"Niue",
          "es":"Niue",
          "ca":"Niue"
       }
    },
    {
       "name":"Norfolk Island",
       "dial_code":"+672",
       "code":"NF",
       "flag":"🇳🇫",
       "translations":{
          "en":"Norfolk Island",
          "es":"Isla de Norfolk",
          "ca":"Illa de Norfolk"
       }
    },
    {
       "name":"Northern Mariana Islands",
       "dial_code":"+1670",
       "code":"MP",
       "flag":"🇲🇵",
       "translations":{
          "en":"Northern Mariana Islands",
          "es":"Islas Marianas del Norte",
          "ca":"Illes Mariannes del Nord"
       }
    },
    {
       "name":"Norway",
       "dial_code":"+47",
       "code":"NO",
       "flag":"🇳🇴",
       "translations":{
          "en":"Norway",
          "es":"Noruega",
          "ca":"Noruega"
       }
    },
    {
       "name":"Oman",
       "dial_code":"+968",
       "code":"OM",
       "flag":"🇴🇲",
       "translations":{
          "en":"Oman",
          "es":"Omán",
          "ca":"Oman"
       }
    },
    {
       "name":"Pakistan",
       "dial_code":"+92",
       "code":"PK",
       "flag":"🇵🇰",
       "translations":{
          "en":"Pakistan",
          "es":"Pakistán",
          "ca":"Pakistan"
       }
    },
    {
       "name":"Palau",
       "dial_code":"+680",
       "code":"PW",
       "flag":"🇵🇼",
       "translations":{
          "en":"Palau",
          "es":"Palau",
          "ca":"Palau"
       }
    },
    {
       "name":"Palestinian Territory, Occupied",
       "dial_code":"+970",
       "code":"PS",
       "flag":"🇵🇸",
       "translations":{
          "en":"Palestinian Territory, Occupied",
          "es":"Territorio Palestino Ocupado",
          "ca":"Territori Palestí Ocupat"
       }
    },
    {
       "name":"Panama",
       "dial_code":"+507",
       "code":"PA",
       "flag":"🇵🇦",
       "translations":{
          "en":"Panama",
          "es":"Panamá",
          "ca":"Panamà"
       }
    },
    {
       "name":"Papua New Guinea",
       "dial_code":"+675",
       "code":"PG",
       "flag":"🇵🇬",
       "translations":{
          "en":"Papua New Guinea",
          "es":"Papúa Nueva Guinea",
          "ca":"Papua Nova Guinea"
       }
    },
    {
       "name":"Paraguay",
       "dial_code":"+595",
       "code":"PY",
       "flag":"🇵🇾",
       "translations":{
          "en":"Paraguay",
          "es":"Paraguay",
          "ca":"Paraguai"
       }
    },
    {
       "name":"Peru",
       "dial_code":"+51",
       "code":"PE",
       "flag":"🇵🇪",
       "translations":{
          "en":"Peru",
          "es":"Perú",
          "ca":"Perú"
       }
    },
    {
       "name":"Philippines",
       "dial_code":"+63",
       "code":"PH",
       "flag":"🇵🇭",
       "translations":{
          "en":"Philippines",
          "es":"Filipinas",
          "ca":"Filipines"
       }
    },
    {
       "name":"Pitcairn",
       "dial_code":"+872",
       "code":"PN",
       "flag":"🇵🇳",
       "translations":{
          "en":"Pitcairn",
          "es":"Pitcairn",
          "ca":"Pitcairn"
       }
    },
    {
       "name":"Poland",
       "dial_code":"+48",
       "code":"PL",
       "flag":"🇵🇱",
       "translations":{
          "en":"Poland",
          "es":"Polonia",
          "ca":"Polònia"
       }
    },
    {
       "name":"Portugal",
       "dial_code":"+351",
       "code":"PT",
       "flag":"🇵🇹",
       "translations":{
          "en":"Portugal",
          "es":"Portugal",
          "ca":"Portugal"
       }
    },
    {
       "name":"Puerto Rico",
       "dial_code":"+1939",
       "code":"PR",
       "flag":"🇵🇷",
       "translations":{
          "en":"Puerto Rico",
          "es":"Puerto Rico",
          "ca":"Puerto Rico"
       }
    },
    {
       "name":"Qatar",
       "dial_code":"+974",
       "code":"QA",
       "flag":"🇶🇦",
       "translations":{
          "en":"Qatar",
          "es":"Catar",
          "ca":"Qatar"
       }
    },
    {
       "name":"Romania",
       "dial_code":"+40",
       "code":"RO",
       "flag":"🇷🇴",
       "translations":{
          "en":"Romania",
          "es":"Rumania",
          "ca":"Romania"
       }
    },
    {
       "name":"Russia",
       "dial_code":"+7",
       "code":"RU",
       "flag":"🇷🇺",
       "translations":{
          "en":"Russia",
          "es":"Rusia",
          "ca":"Rússia"
       }
    },
    {
       "name":"Rwanda",
       "dial_code":"+250",
       "code":"RW",
       "flag":"🇷🇼",
       "translations":{
          "en":"Rwanda",
          "es":"Ruanda",
          "ca":"Ruanda"
       }
    },
    {
       "name":"Réunion",
       "dial_code":"+262",
       "code":"RE",
       "flag":"🇷🇪",
       "translations":{
          "en":"Réunion",
          "es":"Reunión",
          "ca":"Reunió"
       }
    },
    {
       "name":"Saint Barthélemy",
       "dial_code":"+590",
       "code":"BL",
       "flag":"🇧🇱",
       "translations":{
          "en":"Saint Barthélemy",
          "es":"San Bartolomé",
          "ca":"Saint Barthélemy"
       }
    },
    {
       "name":"Saint Helena, Ascension and Tristan Da Cunha",
       "dial_code":"+290",
       "code":"SH",
       "flag":"🇸🇭",
       "translations":{
          "en":"Saint Helena, Ascension and Tristan Da Cunha",
          "es":"Santa Elena, Ascensión y Tristán de Acuña",
          "ca":"Saint Helena, Ascension and Tristan Da Cunha"
       }
    },
    {
       "name":"Saint Kitts and Nevis",
       "dial_code":"+1869",
       "code":"KN",
       "flag":"🇰🇳",
       "translations":{
          "en":"Saint Kitts and Nevis",
          "es":"San Cristóbal y Nieves",
          "ca":"Saint Kitts and Nevis"
       }
    },
    {
       "name":"Saint Lucia",
       "dial_code":"+1758",
       "code":"LC",
       "flag":"🇱🇨",
       "translations":{
          "en":"Saint Lucia",
          "es":"Santa Lucía",
          "ca":"Saint Lucia"
       }
    },
    {
       "name":"Saint Martin",
       "dial_code":"+590",
       "code":"MF",
       "flag":"🇲🇫",
       "translations":{
          "en":"Saint Martin",
          "es":"San Martín",
          "ca":"Saint Martin"
       }
    },
    {
       "name":"Saint Pierre and Miquelon",
       "dial_code":"+508",
       "code":"PM",
       "flag":"🇵🇲",
       "translations":{
          "en":"Saint Pierre and Miquelon",
          "es":"San Pedro y Miquelón",
          "ca":"Saint Pierre i Miquelon"
       }
    },
    {
       "name":"Saint Vincent and the Grenadines",
       "dial_code":"+1784",
       "code":"VC",
       "flag":"🇻🇨",
       "translations":{
          "en":"Saint Vincent and the Grenadines",
          "es":"San Vicente y las Granadinas",
          "ca":"Saint Vincent i les Grenadines"
       }
    },
    {
       "name":"Samoa",
       "dial_code":"+685",
       "code":"WS",
       "flag":"🇼🇸",
       "translations":{
          "en":"Samoa",
          "es":"Samoa",
          "ca":"Samoa"
       }
    },
    {
       "name":"San Marino",
       "dial_code":"+378",
       "code":"SM",
       "flag":"🇸🇲",
       "translations":{
          "en":"San Marino",
          "es":"San Marino",
          "ca":"San Marino"
       }
    },
    {
       "name":"Sao Tome and Principe",
       "dial_code":"+239",
       "code":"ST",
       "flag":"🇸🇹",
       "translations":{
          "en":"Sao Tome and Principe",
          "es":"Santo Tomé y Príncipe",
          "ca":"Sao Tomé i Príncipe"
       }
    },
    {
       "name":"Saudi Arabia",
       "dial_code":"+966",
       "code":"SA",
       "flag":"🇸🇦",
       "translations":{
          "en":"Saudi Arabia",
          "es":"Arabia Saudí",
          "ca":"Aràbia Saudita"
       }
    },
    {
       "name":"Senegal",
       "dial_code":"+221",
       "code":"SN",
       "flag":"🇸🇳",
       "translations":{
          "en":"Senegal",
          "es":"Senegal",
          "ca":"Senegal"
       }
    },
    {
       "name":"Serbia",
       "dial_code":"+381",
       "code":"RS",
       "flag":"🇷🇸",
       "translations":{
          "en":"Serbia",
          "es":"Serbia",
          "ca":"Sèrbia"
       }
    },
    {
       "name":"Seychelles",
       "dial_code":"+248",
       "code":"SC",
       "flag":"🇸🇨",
       "translations":{
          "en":"Seychelles",
          "es":"Seychelles",
          "ca":"Seychelles"
       }
    },
    {
       "name":"Sierra Leone",
       "dial_code":"+232",
       "code":"SL",
       "flag":"🇸🇱",
       "translations":{
          "en":"Sierra Leone",
          "es":"Sierra Leona",
          "ca":"Sierra Leone"
       }
    },
    {
       "name":"Singapore",
       "dial_code":"+65",
       "code":"SG",
       "flag":"🇸🇬",
       "translations":{
          "en":"Singapore",
          "es":"Singapur",
          "ca":"Singapur"
       }
    },
    {
       "name":"Slovakia",
       "dial_code":"+421",
       "code":"SK",
       "flag":"🇸🇰",
       "translations":{
          "en":"Slovakia",
          "es":"Eslovaquia",
          "ca":"Eslovàquia"
       }
    },
    {
       "name":"Slovenia",
       "dial_code":"+386",
       "code":"SI",
       "flag":"🇸🇮",
       "translations":{
          "en":"Slovenia",
          "es":"Eslovenia",
          "ca":"Eslovènia"
       }
    },
    {
       "name":"Solomon Islands",
       "dial_code":"+677",
       "code":"SB",
       "flag":"🇸🇧",
       "translations":{
          "en":"Solomon Islands",
          "es":"Islas Salomón",
          "ca":"Illes Salomó"
       }
    },
    {
       "name":"Somalia",
       "dial_code":"+252",
       "code":"SO",
       "flag":"🇸🇴",
       "translations":{
          "en":"Somalia",
          "es":"Somalia",
          "ca":"Somàlia"
       }
    },
    {
       "name":"South Africa",
       "dial_code":"+27",
       "code":"ZA",
       "flag":"🇿🇦",
       "translations":{
          "en":"South Africa",
          "es":"Sudáfrica",
          "ca":"Sud-àfrica"
       }
    },
    {
       "name":"South Georgia and the South Sandwich Islands",
       "dial_code":"+500",
       "code":"GS",
       "flag":"🇬🇸",
       "translations":{
          "en":"South Georgia and the South Sandwich Islands",
          "es":"Islas Georgia del Sur y Sandwich del Sur",
          "ca":"Illes Geòrgia del Sud i Sandwich del Sud"
       }
    },
    {
       "name":"Spain",
       "dial_code":"+34",
       "code":"ES",
       "flag":"🇪🇸",
       "translations":{
          "en":"Spain",
          "es":"España",
          "ca":"Espanya"
       }
    },
    {
       "name":"Sri Lanka",
       "dial_code":"+94",
       "code":"LK",
       "flag":"🇱🇰",
       "translations":{
          "en":"Sri Lanka",
          "es":"Sri Lanka",
          "ca":" Sri Lanka"
       }
    },
    {
       "name":"Sudan",
       "dial_code":"+249",
       "code":"SD",
       "flag":"🇸🇩",
       "translations":{
          "en":"Sudan",
          "es":"Sudán",
          "ca":"Sudan"
       }
    },
    {
       "name":"South Sudan",
       "dial_code":"+211",
       "code":"SS",
       "flag":"🇸🇸",
       "translations":{
          "en":"South Sudan",
          "es":"Sudán del Sur",
          "ca":"Sudan del Sud"
       }
    },
    {
       "name":"Suriname",
       "dial_code":"+597",
       "code":"SR",
       "flag":"🇸🇷",
       "translations":{
          "en":"Suriname",
          "es":"Surinam",
          "ca":"Surinam"
       }
    },
    {
       "name":"Svalbard and Jan Mayen",
       "dial_code":"+47",
       "code":"SJ",
       "flag":"🇸🇯",
       "translations":{
          "en":"Svalbard and Jan Mayen",
          "es":"Svalbard y Jan Mayen",
          "ca":"Svalbard i Jan Mayen"
       }
    },
    {
       "name":"Swaziland",
       "dial_code":"+268",
       "code":"SZ",
       "flag":"🇸🇿",
       "translations":{
          "en":"Swaziland",
          "es":"Suazilandia",
          "ca":"Suazilàndia"
       }
    },
    {
       "name":"Sweden",
       "dial_code":"+46",
       "code":"SE",
       "flag":"🇸🇪",
       "translations":{
          "en":"Sweden",
          "es":"Suecia",
          "ca":"Suècia"
       }
    },
    {
       "name":"Switzerland",
       "dial_code":"+41",
       "code":"CH",
       "flag":"🇨🇭",
       "translations":{
          "en":"Switzerland",
          "es":"Suiza",
          "ca":"Suïssa"
       }
    },
    {
       "name":"Syrian Arab Republic",
       "dial_code":"+963",
       "code":"SY",
       "flag":"🇸🇾",
       "translations":{
          "en":"Syrian Arab Republic",
          "es":"República Árabe Siria",
          "ca":"República Àrab Síria"
       }
    },
    {
       "name":"Taiwan, Province of China",
       "dial_code":"+886",
       "code":"TW",
       "flag":"🇹🇼",
       "translations":{
          "en":"Taiwan, Province of China",
          "es":"Taiwán, Provincia de China",
          "ca":"Taiwan, Província de la Xina"
       }
    },
    {
       "name":"Tajikistan",
       "dial_code":"+992",
       "code":"TJ",
       "flag":"🇹🇯",
       "translations":{
          "en":"Tajikistan",
          "es":"Tayikistán",
          "ca":"Tadjikistan"
       }
    },
    {
       "name":"Tanzania, United Republic of",
       "dial_code":"+255",
       "code":"TZ",
       "flag":"🇹🇿",
       "translations":{
          "en":"Tanzania, United Republic of",
          "es":"Tanzania, República Unida de",
          "ca":"Tanzània, República Unida de"
       }
    },
    {
       "name":"Thailand",
       "dial_code":"+66",
       "code":"TH",
       "flag":"🇹🇭",
       "translations":{
          "en":"Thailand",
          "es":"Tailandia",
          "ca":"Tailàndia"
       }
    },
    {
       "name":"Timor-Leste",
       "dial_code":"+670",
       "code":"TL",
       "flag":"🇹🇱",
       "translations":{
          "en":"Timor-Leste",
          "es":"Timor Oriental",
          "ca":"Timor Oriental"
       }
    },
    {
       "name":"Togo",
       "dial_code":"+228",
       "code":"TG",
       "flag":"🇹🇬",
       "translations":{
          "en":"Togo",
          "es":"Togo",
          "ca":"Togo"
       }
    },
    {
       "name":"Tokelau",
       "dial_code":"+690",
       "code":"TK",
       "flag":"🇹🇰",
       "translations":{
          "en":"Tokelau",
          "es":"Tokelau",
          "ca":"Tokelau"
       }
    },
    {
       "name":"Tonga",
       "dial_code":"+676",
       "code":"TO",
       "flag":"🇹🇴",
       "translations":{
          "en":"Tonga",
          "es":"Tonga",
          "ca":"Tonga"
       }
    },
    {
       "name":"Trinidad and Tobago",
       "dial_code":"+1868",
       "code":"TT",
       "flag":"🇹🇹",
       "translations":{
          "en":"Trinidad and Tobago",
          "es":"Trinidad y Tobago",
          "ca":"Trinitat i Tobago"
       }
    },
    {
       "name":"Tunisia",
       "dial_code":"+216",
       "code":"TN",
       "flag":"🇹🇳",
       "translations":{
          "en":"Tunisia",
          "es":"Túnez",
          "ca":"Tunísia"
       }
    },
    {
       "name":"Turkey",
       "dial_code":"+90",
       "code":"TR",
       "flag":"🇹🇷",
       "translations":{
          "en":"Turkey",
          "es":"Turquía",
          "ca":"Turquia"
       }
    },
    {
       "name":"Turkmenistan",
       "dial_code":"+993",
       "code":"TM",
       "flag":"🇹🇲",
       "translations":{
          "en":"Turkmenistan",
          "es":"Turkmenistán",
          "ca":"Turkmenistan"
       }
    },
    {
       "name":"Turks and Caicos Islands",
       "dial_code":"+1649",
       "code":"TC",
       "flag":"🇹🇨",
       "translations":{
          "en":"Turks and Caicos Islands",
          "es":"Islas Turcas y Caicos",
          "ca":"Illes Turks i Caicos"
       }
    },
    {
       "name":"Tuvalu",
       "dial_code":"+688",
       "code":"TV",
       "flag":"🇹🇻",
       "translations":{
          "en":"Tuvalu",
          "es":"Tuvalu",
          "ca":"Tuvalu"
       }
    },
    {
       "name":"Uganda",
       "dial_code":"+256",
       "code":"UG",
       "flag":"🇺🇬",
       "translations":{
          "en":"Uganda",
          "es":"Uganda",
          "ca":"Uganda"
       }
    },
    {
       "name":"Ukraine",
       "dial_code":"+380",
       "code":"UA",
       "flag":"🇺🇦",
       "translations":{
          "en":"Ukraine",
          "es":"Ucrania",
          "ca":"Ucraïna"
       }
    },
    {
       "name":"United Arab Emirates",
       "dial_code":"+971",
       "code":"AE",
       "preferred":true,
       "flag":"🇦🇪",
       "translations":{
          "en":"United Arab Emirates",
          "es":"Emiratos Árabes Unidos",
          "ca":"Emirats Àrabs Units"
       }
    },
    {
       "name":"United Kingdom",
       "dial_code":"+44",
       "code":"GB",
       "preferred":true,
       "flag":"🇬🇧",
       "translations":{
          "en":"United Kingdom",
          "es":"Reino Unido",
          "ca":"Regne Unit"
       }
    },
    {
      "name":"United States",
      "dial_code":"+1",
      "code":"US",
      "preferred":true,
      "flag":"🇺🇸",
      "translations":{
         "en":"USA/Canada",
         "es":"EEUU/Canadá",
         "ca":"EEUU/Canadà"
      }
   },
    {
       "name":"Uruguay",
       "dial_code":"+598",
       "code":"UY",
       "flag":"🇺🇾",
       "translations":{
          "en":"Uruguay",
          "es":"Uruguay",
          "ca":"Uruguai"
       }
    },
    {
       "name":"Uzbekistan",
       "dial_code":"+998",
       "code":"UZ",
       "flag":"🇺🇿",
       "translations":{
          "en":"Uzbekistan",
          "es":"Uzbekistán",
          "ca":"Uzbekistan"
       }
    },
    {
       "name":"Vanuatu",
       "dial_code":"+678",
       "code":"VU",
       "flag":"🇻🇺",
       "translations":{
          "en":"Vanuatu",
          "es":"Vanuatu",
          "ca":"Vanuatu"
       }
    },
    {
       "name":"Venezuela, Bolivarian Republic of",
       "dial_code":"+58",
       "code":"VE",
       "flag":"🇻🇪",
       "translations":{
          "en":"Venezuela, Bolivarian Republic of",
          "es":"Venezuela, República Bolivariana de",
          "ca":"Veneçuela, República Bolivariana de"
       }
    },
    {
       "name":"Viet Nam",
       "dial_code":"+84",
       "code":"VN",
       "flag":"🇻🇳",
       "translations":{
          "en":"Viet Nam",
          "es":"Vietnam",
          "ca":"Vietnam"
       }
    },
    {
       "name":"Virgin Islands, British",
       "dial_code":"+1284",
       "code":"VG",
       "flag":"🇻🇬",
       "translations":{
          "en":"Virgin Islands, British",
          "es":"Islas Vírgenes Británicas",
          "ca":"Illes Verges Britàniques"
       }
    },
    {
       "name":"Virgin Islands, U.S.",
       "dial_code":"+1340",
       "code":"VI",
       "flag":"🇻🇮",
       "translations":{
          "en":"Virgin Islands, U.S.",
          "es":"Islas Vírgenes de los Estados Unidos",
          "ca":"Illes Verges dels Estats Units"
       }
    },
    {
       "name":"Wallis and Futuna",
       "dial_code":"+681",
       "code":"WF",
       "flag":"🇼🇫",
       "translations":{
          "en":"Wallis and Futuna",
          "es":"Wallis y Futuna",
          "ca":"Wallis i Futuna"
       }
    },
    {
       "name":"Yemen",
       "dial_code":"+967",
       "code":"YE",
       "flag":"🇾🇪",
       "translations":{
          "en":"Yemen",
          "es":"Yemen",
          "ca":"Iemen"
       }
    },
    {
       "name":"Zambia",
       "dial_code":"+260",
       "code":"ZM",
       "flag":"🇿🇲",
       "translations":{
          "en":"Zambia",
          "es":"Zambia",
          "ca":"Zàmbia"
       }
    },
    {
       "name":"Zimbabwe",
       "dial_code":"+263",
       "code":"ZW",
       "flag":"🇿🇼",
       "translations":{
          "en":"Zimbabwe",
          "es":"Zimbabue",
          "ca":"Zimbàbue"
       }
    },
    {
       "name":"Åland Islands",
       "dial_code":"+358",
       "code":"AX",
       "flag":"🇦🇽",
       "translations":{
          "en":"Åland Islands",
          "es":"Islas Åland",
          "ca":"Illes Åland"
       }
    }
 ]

 export default flags;