import React, { useContext, useState } from "react";
import { useParams } from 'react-router-dom';
import { Fade } from "react-awesome-reveal";

import { format } from "date-fns";

import { Trans } from "react-i18next";

import { DateTimeContext } from "../context/DateTimeContext";
import { AuthContext } from "../context/AuthContext";
import { Button, Segment, Header, Icon } from "semantic-ui-react";
import LoadingComponent from "./LoadingComponent";
import { TimeSlotContext } from "../context/TimeSlotContext";
import { ServiceContext } from "../context/ServiceContext";
import { setInfoAboutCitizen } from "../providers/CalendarProvider";

export default function ConfirmMessage({
  onHandleChangeData,
  onHandleChangeDate,
}) {
  const [startDate] = useContext(DateTimeContext);
  const [values] = useContext(AuthContext);
  // eslint-disable-next-line
  const [serviceValues, setValues] = useContext(ServiceContext);
  const { organization, service} = useParams();
  const [showDateTimeMessage, setDateTimeMessage] = useState(true);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState("");
  const [timeSlot] = useContext(TimeSlotContext);

  const d = new Date(startDate);
  const date = d.toISOString().split("T")[0];
  const time = d.toTimeString().split(" ")[0];
  const id = `${date}T${time}`;

  const slotId = timeSlot.reduce(
    // eslint-disable-next-line
    (a, o) => (o.start === id && a.push(o.id), a),
    []
  );

  const {
    nom,
    cognom,
    segon_cognom,
    email,
    telefon,
    dni,
    idType,
    observations,
    postalCode
  } = values;
  const { category } = {
    category: serviceValues.value || service,
  };
  const citizen = {
    slotId: `${slotId}`,
    serviceCode: `${category}`,
    zipCode: postalCode,
    attendees: [
      {
        isOrganizer: true,
        type: "Citizen",
        name: nom,
        surname: cognom,
        secondSurname: segon_cognom,
        email: email,
        phone: telefon,
        id: dni,
        idType: idType,
        culture: "es-CA",
      },
    ],
    observations: observations,
    isOtpAvailable: true
  };

  const getServiceText = () => {
    const service = window.location.pathname.split("/")[2];
    if(service == null){
      return (
        <>
          <strong>
            <Trans i18nKey="app.management" />
          </strong>
          {serviceValues.text}
          <br />
        </>
      )
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await setInfoAboutCitizen(citizen);

      if (response?.status === 400 || response?.status === 500) {
        throw new Error(response.data[0].message);
      }

      setIsLoading(false);
      setShowConfirmMessage(true);
    } catch (error) {
      setErrorMessageText(error.toString());

      setIsLoading(false);
      setErrorMessage(true);
    }
  };

  return (
    <>
      {showDateTimeMessage && (
        <>
          <Fade>
            <Header
              sub
              color="teal"
              content={<Trans i18nKey="confirm.header" />}
            />

            <Segment
              style={{ marginBottom: "30px" }}
              textAlign="left"
              secondary
            >
              {getServiceText()}
              <strong>
                <Trans i18nKey="app.time_selected" />
              </strong>{" "}
              <span>{format(startDate, "dd-MM-yyyy")}</span>{" "}
              <strong>
                <Trans i18nKey="app.confirm_hour" />
              </strong>{" "}
              <span>
                {format(startDate, "HH:mm")}{" "}
                <Trans i18nKey="confirm.confirm_hour_2" />
              </span>
            </Segment>

            <Segment
              style={{ marginBottom: "30px" }}
              textAlign="left"
              secondary
            >
              <strong>
                <Trans i18nKey="confirm.title" />
              </strong>
              <br />
              <br />
              <p>
                {nom} {cognom} {segon_cognom}
              </p>
              <div style={{ display: "flex", "justify-content": "flex-start" }}>
                <strong>
                  <Trans i18nKey="confirm.confirm_phone" />
                </strong>
                <p>{telefon}</p>
              </div>
              <br />
              <p>{email}</p>
              <div style={{ display: "flex", "justify-content": "flex-start" }}>
                <strong>
                  <Trans i18nKey="confirm.confirm_id" />
                </strong>
                <p>{dni}</p>
              </div>
              <br />

              <strong>
                <Trans i18nKey="confirm.reason" />
              </strong>
              <p>{observations}</p>
            </Segment>
            <div
              className="small-div"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "50px",
                minWidth: "457px",
              }}
            >
              <Button
                content={<Trans i18nKey="confirm.btn_change_data" />}
                color="red"
                basic
                size="medium"
                className="button"
                onClick={() => onHandleChangeData && onHandleChangeData()}
              ></Button>
              <Button
                content={<Trans i18nKey="confirm.btn_change_date" />}
                color="red"
                basic
                size="medium"
                className="button"
                onClick={() => onHandleChangeDate && onHandleChangeDate()}
              ></Button>
              <Button
                labelPosition="right"
                icon="checkmark"
                content={<Trans i18nKey="confirm.btn_confirm" />}
                size="big"
                negative
                onClick={() => {
                  setDateTimeMessage(false);

                  handleSubmit();
                }}
              ></Button>
            </div>
          </Fade>
        </>
      )}
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          {showConfirmMessage && (
            <Fade cascade>
              <h3 style={{ marginTop: "40px" }}>
                <Icon color="green" name="check circle" />
                <Trans i18nKey="confirm.confirm_text_1" />
              </h3>
              <p style={{ marginTop: "40px" }}>
                <Trans i18nKey="confirm.confirm_text_2" />
              </p>
              <p>
                <Trans i18nKey="confirm.confirm_text_3" />
              </p>
              <p>
                <Trans i18nKey="confirm.confirm_text_4" />
              </p>
              <Button
                content={<Trans i18nKey="exit" />}
                negative
                size="big"
                style={{ margin: "15px", marginTop: "20px" }}
                onClick={() => window.location.reload(false)}
              ></Button>
            </Fade>
          )}
        </>
      )}
      {errorMessage && (
        <div>
          <h3 style={{ marginTop: "40px" }}>
            <Icon color="red" name="close" />
            <Trans i18nKey="confirm.error_confirm" />
            <br />
            <br />

            <Trans i18nKey="confirm.error_confirm2" />
            <br />
          </h3>
          <Button
            content={<Trans i18nKey="confirm.btn_exit" />}
            negative
            size="big"
            style={{ margin: "15px" }}
            onClick={() => window.location.reload(false)}
          ></Button>
        </div>
      )}
    </>
  );
}
