import React, { useState, createContext } from "react";

export const TimeSlotContext = createContext();

export const TimeSlotContextProvider = (props) => {
  const [timeSlot, setTimeSlot] = useState();

  return (
    <TimeSlotContext.Provider value={[timeSlot, setTimeSlot]}>
      {props.children}
    </TimeSlotContext.Provider>
  );
};
