import React, { useState, useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import CreateDate from "./components/CreateDate";
import CancelMeeting from "./components/CancelMeeting";
import Header from "./components/Header";
import Footer from "./components/Footer";

import "./App.css";

import "semantic-ui-css/semantic.min.css";

import "react-datepicker/dist/react-datepicker.css";
import DateSelect from "./components/Calendar";

function App() {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <BrowserRouter>
        <Switch>
          <div className="App">
            <h1 id="AppTitle">
              {t('title')}
            </h1>
            <Route path="/:organization" component={CreateDate} exact={true} />
            <Route path="/:organization/:service" component={CreateDate} exact={true} />
            <Route
              path="/cancel/:meeting/:slot"
              component={CancelMeeting}
              exact={true}
            />
          </div>
        </Switch>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
