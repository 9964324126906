import { fetch, fetchWithBody } from "../util/FetchUtil";
import { EnumHttpMethod } from "../types/EnumHttpMethod";

import { getLanguageNavigator } from "../util/languages";

export const getEvents = async (year, month, category) => {
  if(!window.location.href.includes('cancel')){
    const endpoint = `${process.env.REACT_APP_API_URL}Calendar?y=${year}&m=${month}&sc=${category}&c=${getLanguageNavigator()}`;
  
    try {
      const response = await fetch(endpoint, EnumHttpMethod.GET);
      return response;
    } catch (error) {
      console.error(error);
    }
  }
};

export const getAvailibilitySlots = async (day, serviceCode) => {
  console.log("Day And Service", day, serviceCode)
  const endpoint = `${process.env.REACT_APP_API_URL}Calendar/${day}?sc=${serviceCode}&c=${getLanguageNavigator()}`;

  try {
    const response = await fetch(endpoint, EnumHttpMethod.GET);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const setInfoAboutCitizen = async (form) => {
  const endpoint = `${process.env.REACT_APP_API_URL}Calendar?c=${getLanguageNavigator()}`;

  try {
    const response = await fetchWithBody(endpoint, EnumHttpMethod.POST, form);
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
