import React, { useState, createContext } from "react";
import Screens from "../constants/Screens";

// Create Context Object
export const UIContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const UIContextProvider = (props) => {
  const [state, setState] = useState({
    currentForm: Screens.SELECT_FORM,
    formCompleted: {},
  });

  const nextRoute = {
    [Screens.SELECT_FORM]: Screens.CALENDAR,
    [Screens.CALENDAR]: Screens.TIME_SELECT,
    [Screens.TIME_SELECT]: Screens.FIRST_DATE_TIME_MESSAGE,
    [Screens.FIRST_DATE_TIME_MESSAGE]: Screens.CITA_PREVIA_FORM,
    [Screens.CITA_PREVIA_FORM]: Screens.CONFIRM_MESSAGE,
  };

  const nextPage = (newState) => {
    const currentForm = (newState && newState.currentForm) || state.currentForm;
    const formCompleted =
      (newState && newState.formCompleted) || state.formCompleted;

    console.log("form completed", state.formCompleted);
    let nextState = nextRoute[currentForm];
    console.log("Next State", nextState)
    while (formCompleted[nextState]) {
      nextState = nextRoute[nextState];
    }
    setState({
      currentForm: nextState,
      formCompleted: { ...formCompleted, [currentForm]: true },
    });
  };

  const clearFormAndBack = (screensCleaned) => {
    screensCleaned = Array.isArray(screensCleaned)
      ? screensCleaned
      : [screensCleaned];
    const newStatusFormCompleted = {};
    screensCleaned.forEach((_) => (newStatusFormCompleted[_] = false));
    nextPage({
      currentForm: Screens.SELECT_FORM,
      formCompleted: { ...state.formCompleted, ...newStatusFormCompleted },
    });
  };

  const setCurrentForm = (newCurrentForm) => {
    setState({
      currentForm: newCurrentForm,
      formCompleted: {SELECT_FORM: true}
    })
  }

  return (
    <UIContext.Provider
      value={{
        currentForm: state.currentForm,
        nextPage,
        clearFormAndBack,
        setCurrentForm
      }}
    >
      {props.children}
    </UIContext.Provider>
  );
};
