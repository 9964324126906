import React, { useState, createContext } from "react";

// Create Context Object
export const ServiceContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const ServiceContextProvider = (props) => {
  const [values, setValues] = useState({});

  return (
    <ServiceContext.Provider value={[values, setValues]}>
      {props.children}
    </ServiceContext.Provider>
  );
};
