import React, { useState, useContext, useEffect } from "react";

import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

import { DateTimeContext } from "../context/DateTimeContext";
import { format } from "date-fns";
import { Fade } from "react-awesome-reveal";

import { useOnScreen } from "../hooks/useOnScreen";

import SelectForm from '../components/SelectService';
//import SelectForm from '../components/SelectArea';
import TimeSelect from '../components/TimeSelect';
import DateSelect from '../components/Calendar';
import CitaPreviaForm from '../components/form/CitaPreviaForm';
import ConfirmMessage from '../components/ConfirmMessage';
import FirstConfirmMessage from '../components/FirstConfirmMessage';
import { UIContext } from '../context/UIContext';

import "../App.css";
import Screens from "../constants/Screens";
import { useParams } from "react-router";

const CreateDate = () => {
  const [startDate, setStartDate] = useContext(DateTimeContext);
  const { organization, service } = useParams();

  const { currentForm, nextPage, clearFormAndBack, setCurrentForm } = useContext(UIContext);

  const [ref, visible] = useOnScreen({
    root: document.querySelector("#root"),
    rootMargin: "0px",
  });

  useEffect(() => {
    if(service != undefined){
      setCurrentForm(Screens.CALENDAR)
      console.log(currentForm);
    }
  }, [])

  return (
    <>
      <>
        {currentForm == Screens.SELECT_FORM && (
          <SelectForm onHandleClickNext={nextPage} />
        )}
      </>

      <div className="calendar-container">
        {currentForm == Screens.CALENDAR && (
          <DateSelect onHandleClickNext={nextPage} />
        )}
      </div>

      <div>
        {currentForm == Screens.TIME_SELECT && (
          <>
            <Fade cascade>
              <TimeSelect onHandleClickNext={nextPage} />
            </Fade>
          </>
        )}
      </div>

      {currentForm == Screens.FIRST_DATE_TIME_MESSAGE && (
        <FirstConfirmMessage
          onHandleClickBack={() =>
            clearFormAndBack([Screens.CALENDAR, Screens.TIME_SELECT])
          }
          onHandleClickNext={nextPage}
        />
      )}
      {currentForm == Screens.CITA_PREVIA_FORM && (
        <CitaPreviaForm onHandleClickNext={nextPage} />
      )}

      <>
        {currentForm == Screens.CONFIRM_MESSAGE && (
          <ConfirmMessage
            onHandleChangeData={() =>
              clearFormAndBack([Screens.CITA_PREVIA_FORM])
            }
            onHandleChangeDate={() =>
              clearFormAndBack([Screens.CALENDAR, Screens.TIME_SELECT])
            }
          />
        )}
      </>
    </>
  );
};

export default CreateDate;
