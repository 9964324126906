import React, { useState, createContext } from "react";

// Create Context Object
export const AuthContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const AuthContextProvider = (props) => {
  const [values, setValues] = useState({});

  return (
    <AuthContext.Provider value={[values, setValues]}>
      {props.children}
    </AuthContext.Provider>
  );
};
