import React, { useContext, useEffect, useState } from "react";
import { Segment, Header, Button } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { Fade } from "react-awesome-reveal";

import LoadingComponent from "./LoadingComponent";
import { getLanguageNavigator } from "../util/languages";
import { Trans } from "react-i18next";

import * as Yup from "yup";

import MySelectInput from "./form/MySelectInput";
import { ServiceContext } from "../context/ServiceContext";
import { getServices } from "../providers/ServiceProvider";
import { useParams } from "react-router";

export default function SelectForm({ onHandleClickNext }) {
  const { organization } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [dataService, setDataService] = useState([]);
  const [dataArea, setDataArea] = useState([]);
  const [isError, setIsError] = useState(false);

  // eslint-disable-next-line
  const [serviceValues, setValues] = useContext(ServiceContext);

  const initialValues = {
    area: "",
    category: ''
  };

  const validationSchema = Yup.object({
    area: Yup.string().required(<Trans i18nKey="area.required_area" />),
    category: Yup.string().required(<Trans i18nKey="service.required_service" />)
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const resultServices = await getServices(organization);

        const { data } = resultServices;
        setDataService(data);

        setIsLoading(false);
      } catch (err) {
        setIsError(true);
        setIsLoading(false);
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const arrayOfServices = dataService;
  const newArrayOfServices = arrayOfServices.map(
    ({ displayName: text, ...rest }) => ({ text, ...rest }
    ));
  const newArrayOfServices1 = newArrayOfServices.map(
    ({ code: value, ...rest }) => ({ value, ...rest }
    ));

  function removeDuplicates(array, key) {
    var check = new Set();
    return array.filter(obj => !check.has(obj[key]) && check.add(obj[key]));
  }

  const availableAreas = () => {
    if (dataArea.length < 1) {
      for (var i = 0; i < arrayOfServices.length; i++) {
        const { areaCode, areaDisplayName } = arrayOfServices[i];
        setDataArea(prevArea => [...prevArea, {areaCode, areaDisplayName}])
      }
    }

    const arrayOfAreas = removeDuplicates(dataArea, 'areaCode');

    const newArrayOfAreas = arrayOfAreas.map(
      (area) => ({ text: area.areaDisplayName, value: area.areaCode }));
    return newArrayOfAreas
  }

  const availableServices = (area) => {
    const filtro = newArrayOfServices1.filter(({ areaCode }) => areaCode == area);
    return filtro
  }

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <Fade>
            <>
              <Segment clearing>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    setValues(
                      newArrayOfServices1.find(
                        (texto) => texto.value === values.category
                      )
                    );
                    onHandleClickNext && onHandleClickNext();
                  }}
                >
                  {({ isSubmitting, dirty, isValid, values }) => {
                    return (
                      <>
                        <Form className='ui form' >
                          <Header sub color='teal' content={<Trans i18nKey="area.area_select_header" />} />
                          <MySelectInput
                            name='area'
                            placeholder={<Trans i18nKey="area.area_select" />}
                            options={availableAreas()}
                            fluid
                            selection
                          />
                        </Form>

                        <Form className='ui form'>
                          <Header sub color='teal' content={<Trans i18nKey="service.service_select_header" />} />
                          <MySelectInput
                            name='category'
                            placeholder={<Trans i18nKey="service.service_select" />}
                            options={availableServices(values.area)}
                            loading={isSubmitting}
                            disabled={values.area == ""}
                            selected={false}
                            fluid
                            selection
                          />
                        </Form>

                        <Form style={{ marginTop: 20 }}>
                          {isValid && dirty && !isSubmitting ?
                            <Button
                              icon='chevron right'
                              labelPosition='right'
                              loading={isSubmitting}
                              type='submit'
                              floated='right'
                              size='big'
                              negative
                              content={<Trans i18nKey="service.btn_service" />}
                            />
                            :
                            <Button
                              icon='chevron right'
                              labelPosition='right'
                              loading={isSubmitting}
                              disabled
                              size='big'
                              negative
                              floated='right'
                              content={<Trans i18nKey="service.btn_service" />}
                            />
                          }
                        </Form>
                      </>
                    )
                  }}
                </Formik>
              </Segment>
            </>
          </Fade>
        </>
      )}
    </>
  );
}
