import React, { useState, createContext } from "react";

export const DateTimeContext = createContext();

export const DateTimeContextProvider = (props) => {
  const today = new Date();
  const [startDate, setStartDate] = useState(today);

  console.log("Fecha de Inicio", startDate);

  return (
    <DateTimeContext.Provider value={[startDate, setStartDate]}>
      {props.children}
    </DateTimeContext.Provider>
  );
};
