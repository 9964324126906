import React, { Suspense, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./web.config";

import { DateTimeContextProvider } from "./context/DateTimeContext";
import { AuthContextProvider } from "./context/AuthContext";
import { TimeSlotContextProvider } from "./context/TimeSlotContext";
import { UIContextProvider } from "./context/UIContext";
import { ServiceContextProvider } from "./context/ServiceContext";

import {useTranslation } from "react-i18next";
import i18n from "./config/i18n";
import { I18nextProvider } from "react-i18next";



ReactDOM.render(
  <ServiceContextProvider>
    <UIContextProvider>
      <TimeSlotContextProvider>
        <AuthContextProvider>
          <DateTimeContextProvider>
            <Suspense fallback={<div>Loading...</div>}>
              <I18nextProvider i18n={i18n}>
                <App />
              </I18nextProvider>
            </Suspense>
          </DateTimeContextProvider>
        </AuthContextProvider>
      </TimeSlotContextProvider>
    </UIContextProvider>
  </ServiceContextProvider>,
  document.getElementById("root")
);
