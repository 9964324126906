import React, { useContext, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Fade } from "react-awesome-reveal";

import DatePicker, { registerLocale } from "react-datepicker";
import ca from "date-fns/locale/ca";
import moment from "moment";
import { DateTimeContext } from "../context/DateTimeContext";
import { TimeSlotContext } from "../context/TimeSlotContext";
import LoadingComponent from "./LoadingComponent";
import { UIContext } from "../context/UIContext";
import { ServiceContext } from "../context/ServiceContext";
import { Button, Segment, Header, Pagination } from "semantic-ui-react";
import { format } from "date-fns";

import { Trans, useTranslation } from "react-i18next";
import { getAvailibilitySlots } from "../providers/CalendarProvider";

registerLocale("ca", ca);

export default function TimeSelect(props) {
  const [startDate, setStartDate] = useContext(DateTimeContext);
  // eslint-disable-next-line
  const [timeSlot, setTimeSlot] = useContext(TimeSlotContext);
  // eslint-disable-next-line
  const [serviceValues] =  useContext(ServiceContext);
  const { organization, service } = useParams();
  const [hourData, setHourData] = useState();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [datesList, setDatesList] = useState([]);

  const { t } = useTranslation();

  let pickedDay = new Date(startDate);
  let formattedDate =
    pickedDay.getFullYear() +
    ("0" + (pickedDay.getMonth() + 1)).slice(-2) +
    ("0" + pickedDay.getDate()).slice(-2);

  const { category } = {
    category: serviceValues.value || service,
  };


  useEffect(() => {
    console.log(category)

    if (category) {
      const fetchData = async () => {
        try {
          const result = await getAvailibilitySlots(formattedDate, category);

          const currentTime = new Date().getTime();
          const slotsAvailable = [];

          result.data.filter((date) => {
            if (new Date(date.start).getTime() > currentTime)
              slotsAvailable.push(date);
          });

          setData(slotsAvailable);
          setTimeSlot(slotsAvailable);
          setIsLoading(false);
        } catch (err) {
          setIsError(true);
          setIsLoading(false);
          console.log(err);
        }
      };
      fetchData();
    }
  }, [formattedDate, setTimeSlot, category]);

  let availableSlots = data.map((slot) => slot.start);
  availableSlots = availableSlots.map((d) => new Date(d));

  let arrayOfSlots = () => {
    let duplicatedSlots = data.map((slot) => slot.start);
    duplicatedSlots = duplicatedSlots.map((d) => new Date(d));
    console.log("og: ", duplicatedSlots);
    let arraySlots = [];
    while (duplicatedSlots.length) {
      arraySlots.push(duplicatedSlots.splice(0, 20));
    }
    console.log(arraySlots);
    return arraySlots;
  };

  let arrayofslotstwo = arrayOfSlots();

  let handleColor = (time) => {
    return time.getHours() > 1 && time.getHours() < 24
      ? "text-success"
      : "text-error";
  };

  useEffect(() => {
    console.log(arrayofslotstwo[currentPage]);
  }, [currentPage]);

  let handlePageChange = (e, d) => {
    console.log("page : ", d);
    setCurrentPage(d.activePage);
    setDatesList(arrayofslotstwo[d.activePage]);
  };

  const getServiceText = () => {
    const service = window.location.pathname.split("/")[2];
    if(service == null){
      return (
        <>
          <strong>
            <Trans i18nKey="app.management" />
          </strong>
          {serviceValues.text}
          <br />
        </>
      )
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <Fade>
            <Segment
              style={{ marginBottom: "30px" }}
              textAlign="left"
              secondary
            >
              {getServiceText()}
              <strong>
                <Trans i18nKey="app.time_selected" />
              </strong>{" "}
              <span>{format(startDate, "dd.MM.yyyy")}</span>
            </Segment>
            <Header
              sub
              color="teal"
              content={<Trans i18nKey="app.timetable_selection" />}
            />

            {isError || !availableSlots?.length ? (
              <>
                <p>
                  <Trans i18nKey="timer.error_timer" />
                </p>
                <Button
                  content={<Trans i18nKey="timer.btn_return" />}
                  negative
                  size="big"
                  className="button"
                  onClick={() => window.location.reload(false)}
                ></Button>
              </>
            ) : (
              <>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setHourData(date);
                  }}
                  timeClassName={handleColor}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  includeTimes={arrayofslotstwo[currentPage - 1]}
                  timeCaption="Time"
                  selected={hourData || null}
                  timeFormat="HH:mm"
                  inline
                  locale="ca"
                />
                {arrayofslotstwo.length > 1 && (
                  <Pagination
                    activePage={currentPage}
                    onPageChange={handlePageChange}
                    totalPages={arrayofslotstwo.length}
                  />
                )}
                <Button
                  icon="chevron right"
                  labelPosition="right"
                  negative
                  disabled={hourData === undefined}
                  onClick={() => {
                    setStartDate((prevStartDate) => {
                      const startDateWithHours = moment(prevStartDate).set({
                        h: hourData.getHours(),
                        m: hourData.getMinutes(),
                        s: "0",
                      });

                      const startFullDate = new Date(startDateWithHours);

                      return startFullDate;
                    });
                    props.onHandleClickNext && props.onHandleClickNext();
                  }}
                  content={t("next")}
                  negative
                  size="big"
                  style={{ margin: "15px" }}
                ></Button>
              </>
            )}
          </Fade>
        </>
      )}
    </>
  );
}
