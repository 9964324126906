import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

import { Trans } from "react-i18next";

export default function LoadingComponent({
  inverted = true,
  content = <Trans i18nKey="loading" />,
}) {
  return (
    <Dimmer style={{ marginTop: "-100px" }} inverted={inverted} active={true}>
      <Loader content={<Trans i18nKey="loading" />} />
    </Dimmer>
  );
}
