import React, { useContext, useState } from "react";
import { Segment, Header, Dropdown, Button, Checkbox, Modal } from "semantic-ui-react";
import { Formik, Form } from "formik";

import { Trans, useTranslation } from "react-i18next";
import { format } from "date-fns";
import { DateTimeContext } from "../../context/DateTimeContext";

import * as Yup from "yup";
import MyTextInput from "./MyTextInput";
import { AuthContext } from "../../context/AuthContext";
import { ServiceContext } from "../../context/ServiceContext";
import MyTextArea from "./MyTextArea";
import MySelectInput from "./MySelectInput";
import {getPhonePrefixes} from "../../providers/PrefixProvider"


export default function CitaPreviaForm({ onHandleClickNext }) {
  const [serviceValues, setValuesService] = useContext(ServiceContext);
  const [startDate] = useContext(DateTimeContext);
  const [open, setOpen] = React.useState(false);
  const [prefix, setPrefix] = useState('+34');
  


  const { t, i18n } = useTranslation();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    nom: Yup.string().required(<Trans i18nKey="form.required_form" />),
    cognom: Yup.string().required(<Trans i18nKey="form.required_form" />),
    //segon_cognom: Yup.string().required(<Trans i18nKey="form.required_form" />),
    email: Yup.string().email("Email invalido"),
    telefon: Yup.string()
      .matches(phoneRegExp, "El format és incorrecte, inseriu un número de telèfon vàlid sense prefixos ni espais")
      .min(9, <Trans i18nKey="form.required_form_number" />)
      .max(15, <Trans i18nKey="form.required_form_number" />)
      .required(<Trans i18nKey="form.required_form" />),
    dni: Yup.string().required(<Trans i18nKey="form.required_form" />),
    postalCode:Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, <Trans i18nKey="form.required_form_number" />)
    .max(5, <Trans i18nKey="form.required_form_number" />),
    idType: Yup.string().required(<Trans i18nKey="form.required_form" />),
  });
  // eslint-disable-next-line
  const [values, setValues] = useContext(AuthContext);
  const [checked, setChecked] = useState(false);

  const initialValues = {
    tramite: values.tramite || "",
    districto: values.districto || "",
    nom: values.nom || "",
    cognom: values.cognom || "",
    segon_cognom: values.segon_cognom || "",
    email: values.email || "",
    prefix: values.prefix || "+34",
    telefon: values.telefon ? values.telefon.substring((values.prefix.length)) : "",
    dni: values.dni || "",
    postalCode: values.postalCode || "",
    idType: values.idType || "",
    observations: values.observations || "",
  };
  function setCheckbox() {
    setChecked(!checked);
  }

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
    setChecked(true);
  }

  const getServiceText = () => {
    const service = window.location.pathname.split("/")[2];
    if(service == null){
      return (
        <>
          <strong>
            <Trans i18nKey="app.management" />
          </strong>
          {serviceValues.text}
          <br />
        </>
      )
    }
  }

  return (
    <Segment clearing style={{ width: "470px" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={(values) => {
          values.telefon = `${prefix}${values.telefon}`;
          values.prefix = `${prefix}`;
          setValues(values);
          onHandleClickNext && onHandleClickNext();
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form className="ui form" style={{ "text-align": "left" }}>
            <Segment
              style={{ marginBottom: "30px" }}
              textAlign="left"
              secondary
            >
              {getServiceText()}
              <strong>
                <Trans i18nKey="app.time_selected" />
              </strong>{" "}
              <span>{format(startDate, "dd-MM-yyyy")}</span>{" "}
              <strong>
                <Trans i18nKey="app.confirm_hour" />
              </strong>{" "}
              <span>
                {format(startDate, "HH:mm")}{" "}
                <Trans i18nKey="confirm.confirm_hour_2" />
              </span>
            </Segment>

            <Header
              sub
              color="blue"
              style={{ "text-align": "center" }}
              content={<Trans i18nKey="form.form_header" />}
            />
            <MyTextInput
              name="nom"
              placeholder={i18n.t("form.form_lbl_firstname")}
              required
            />
            <MyTextInput
              name="cognom"
              placeholder={i18n.t("form.form_lbl_lastname_1")}
              required
            />
            <MyTextInput
              name="segon_cognom"
              placeholder={i18n.t("form.form_lbl_lastname_2")}
            />
            <MyTextInput
              name="rao"
              placeholder={i18n.t("form.form_lbl_reason1")}
            />

            <label>
              <strong>
                <Trans i18nKey="form.id.label" />
              </strong>
            </label>
            <MySelectInput
              name="idType"
              options={[
                { text: t("form.id.dni"), value: "DNI" },
                { text: t("form.id.nie"), value: "NIE" },
                { text: t("form.id.passport"), value: "PASSPORT" },
                { text: t("form.id.idext"), value: "IDEXT" },
                { text: t("form.id.nife"), value: "NIFE" },
              ]}
              fluid
              selection
            />

            <MyTextInput
              name="dni"
              placeholder={i18n.t("form.form_lbl_id")}
              required
            />

            <MyTextInput
              name="telefon"
              label={(<Dropdown name="prefix" defaultValue={initialValues.prefix} options={getPhonePrefixes() } onChange={(event, data) => setPrefix(data.value)} selection search/> )}
              placeholder={i18n.t("form.form_lbl_phone")}
              required
            />
            <MyTextInput
              name="email"
              placeholder={i18n.t("form.form_lbl_email")}
            />
            <MyTextInput
              name="postalCode"
              placeholder={i18n.t("form.form_lbl_postalCode")}
            />
            <label>
              <strong>
                <Trans i18nKey="form.form_lbl_motivo" />
              </strong>
            </label>
            <br />
            <label>
              <Trans i18nKey="form.form_lbl_motivo_description" />
            </label>
            <MyTextArea name="observations" />
            <div style={{ marginTop: "30px", marginBottom: "30px" }}>
              <Checkbox checked={checked} onChange={setCheckbox} />
              <strong>
                <Trans i18nKey="form.form_lb1_checkbox1" />
              </strong>
              <strong
                onClick={openModal}
                style={{ color: "#C00000", cursor: "pointer" }}
              >
                <Trans i18nKey="form.form_lb1_checkbox2" />
              </strong>
            </div>
            {console.log(!isValid, isSubmitting, !checked)}
            <Button
              loading={isSubmitting}
              disabled={!isValid || isSubmitting || !checked}
              type="submit"
              size="big"
              icon="chevron right"
              labelPosition="right"
              floated="right"
              content={<Trans i18nKey="service.btn_service" />}
              negative
            />
          </Form>
        )}
      </Formik>
      {open && (
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          size="tiny"
        >
          <Modal.Header>{t("data_protection.title")}</Modal.Header>
          <Modal.Content image>
            <Modal.Description
              style={{ width: "100%", height: "300px", overflowY: "scroll" }}
            >

              <p>
                <b>
                  <Trans i18nKey="gdpr.gpg_1" />
                </b>
              </p>
              <p>
                <b>
                  <Trans i18nKey="gdpr.gpg_2" />:
                </b>
                <Trans i18nKey="gdpr.gpg_3" />
              </p>
              <p>
                <b>
                  <Trans i18nKey="gdpr.gpg_4" />:
                </b>
                <Trans i18nKey="gdpr.gpg_5" />
              </p>
              <p>
                <b>
                  <Trans i18nKey="gdpr.gpg_6" />:
                </b>
                <Trans i18nKey="gdpr.gpg_7" />
                <a href="https://presidencia.gencat.cat/ca/el_departament/proteccio-dades/drets-de-les-persones-interessades" target="_blank"><Trans i18nKey="gdpr.gpg_8" /></a>
              </p>
              <p>
                <b>
                <a href="https://presidencia.gencat.cat/ca/el_departament/proteccio-dades/informacio-detallada-tractaments/llistat-per-responsables/158-servei-atencio-ciutadana-videoconferencia" target="_blank"><Trans i18nKey="gdpr.gpg_9" /></a>
                </b>
              </p>
              
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              content={i18n.t("form.modal.button")}
              labelPosition="right"
              icon="checkmark"
              onClick={() => closeModal()}
            />
          </Modal.Actions>
        </Modal>
      )}
    </Segment>
  );
}
