import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import axios from "axios";
import { Trans } from "react-i18next";

import { Fade } from "react-awesome-reveal";
import {
  Button,
  Modal,
  Message,
  Input,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";

const CancelMeeting = (props) => {
  const history = useHistory();
  const params = useParams();
  const { t } = useTranslation();

  const { meeting } = params;
  const { slot } = params;
  const [organization, setOrganization] = useState();

  const [day, setDay] = useState();
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [hour, setHour] = useState();
  const [minute, setMinute] = useState();

  const [open, setOpen] = useState(false);

  const [phone, setPhone] = useState("");

  const [cancel, setCancel] = useState(false);
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getInfoFromUrl();
    getOrganizationFromId();
    hiddenMessage();
  });

  const hiddenMessage = () => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const getOrganizationFromId = async () => {
    const url = `${process.env.REACT_APP_API_URL}Organization/${meeting}/${slot}`;

    await fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setOrganization(response);
      });
  };

  const getInfoFromUrl = async () => {
    setYear(slot.slice(0, 4));
    setMonth(slot.slice(4, 6));
    setDay(slot.slice(6, 8));
    setHour(slot.slice(9, 11));
    setMinute(slot.slice(11, 13));
  };

  const validatePhone = () => {
    const regex = new RegExp("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$");

    return regex.test(phone);
  };

  const handleSubmit = async () => {
    const url = `${process.env.REACT_APP_API_URL}Calendar/${slot}/${meeting}`;
    const prefixPhone = `+34${phone}`;

    await setPhone(prefixPhone);

    if (validatePhone()) {
      try {
        await axios
          .delete(url, { params: { phone: prefixPhone } })
          .then(async (response) => {
            if (response.status || response.ok) await setCancel(true);

            throw new Error();
          });
      } catch (error) {
      } finally {
        setOpen(false);
      }
    } else {
      alert("Telefono invalido");
    }
  };

  return (
    <>
      <div className="cancel">
        <Fade style={{ marginTop: "50px", textAlign: "center" }}>
          <div>
            {error ? (
              <Message error>
                <Message.Header>{t("cancel.error.title")}</Message.Header>
                <p>{t("cancel.error.message")}</p>
                <small>
                  ERROR {errorCode}: {errorMessage}
                </small>
              </Message>
            ) : (
              ""
            )}
          </div>

          <>
            {cancel ? (
              <>
                <p
                  style={{
                    marginTop: "70px",
                    display: "flex",
                    "justify-content": "center",
                    fontFamily: "sans-serif",
                    fontWeight: "400",
                    fontSize: "20px",
                  }}
                >
                  <Icon color="green" name="check circle" />

                  <Trans i18nKey="cancel.success.message" />
                  <div
                    style={{
                      marginLeft: "7px",
                      color: "#3d8bc4",
                      fontFamily: "sans-serif",
                      fontWeight: "400",
                      fontSize: "20px",
                    }}
                  >
                    {meeting}
                  </div>
                </p>

                <Button
                  content={t("confirm.btn_exit")}
                  negative
                  size="big"
                  onClick={() => {
                    history.replace(`/${organization}`);
                  }}
                ></Button>
              </>
            ) : (
              <>
                <h2>
                  {t("cancel.title")}
                  <br />
                  {t("cancel.title2")}
                </h2>
                <p
                  style={{
                    display: "flex",
                    "justify-content": "center",
                    color: "#C00000",
                    fontFamily: "sans-serif",
                    fontWeight: "400",
                    fontSize: "20px",
                  }}
                >
                  <Trans i18nKey="cancel.question" />
                  <div
                    style={{
                      marginLeft: "7px",
                      color: "#3d8bc4",
                      fontFamily: "sans-serif",
                      fontWeight: "400",
                      fontSize: "20px",
                    }}
                  >
                    {meeting}
                  </div>
                  ?
                </p>
                <div style={{ display: "flex", "justify-content": "center" }}>
                  <Segment
                    style={{
                      marginBottom: "30px",
                      marginTop: "20px",
                      minWidth: "250px",
                    }}
                    textAlign="left"
                    secondary
                  >
                    <strong>{t("cancel.day")}</strong> {day}-{month}-{year}{" "}
                    <strong>{t("cancel.hour")}</strong> {hour}:{minute}
                  </Segment>
                </div>
                <p>
                  <br />
                </p>
                <Button
                  content={<Trans i18nKey="cancel.cancel" />}
                  color="red"
                  basic
                  size="big"
                  style={{ width: "100px" }}
                  className="button"
                  onClick={() => window.location.reload(false)}
                ></Button>
                <Modal
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  open={open}
                  style={{
                    minWidth: "200px",
                    maxWidth: "400px",
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  trigger={
                    <Button
                      negative
                      style={{ width: "100px" }}
                      size="big"
                      color="blue"
                    >
                      {t("cancel.open_modal")}
                    </Button>
                  }
                >
                  <Modal.Header>{t("cancel.titleModal")}</Modal.Header>

                  <Modal.Content>
                    <Modal.Description>
                      <div
                        style={{
                          minWidth: "200px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {t("cancel.message_modal")}
                          <br />
                          {t("cancel.message_modal2")}
                        </p>
                        <Input
                          placeholder={t("cancel.input")}
                          label="+34"
                          type="number"
                          value={phone}
                          style={{ width: "100%" }}
                          required
                          onChange={(event) => setPhone(event.target.value)}
                        />
                        {phone !== "" ? (
                          <Button
                            negative
                            color="blue"
                            style={{ marginTop: "1em" }}
                            onClick={handleSubmit}
                          >
                            {t("cancel.confirm")}
                          </Button>
                        ) : (
                          <Button
                            negative
                            disabled
                            color="blue"
                            style={{ marginTop: "1em" }}
                          >
                            {t("cancel.confirm")}
                          </Button>
                        )}
                      </div>
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </>
            )}
          </>
        </Fade>
      </div>
    </>
  );
};

export default CancelMeeting;
