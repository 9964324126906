import React from "react";
import { useField } from "formik";
import { Label, FormField, Input } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

export default function MyTextInput({ label, ...props }) {
  props.placeholder = props.placeholder + (props.required ? "*" : "");
  const [field, meta] = useField(props);
  const { t, i18n } = useTranslation();
  return (
    <FormField error={meta.touched && !!meta.error}>
      <label for={props.name} id={props.name}>
        {props.placeholder}
      </label>
      <Input label={label} {...field} />
      {meta.touched && meta.error ? (
        <Label style={{ marginTop: ".5rem", border: "0px" }} basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </FormField>
  );
}
