import { EnumLang } from "../types/EnumLang";


export const getLanguageNavigator = () => {
  const langNavigator = localStorage.getItem("language");
    if (
      langNavigator === EnumLang.ca ||
      langNavigator === EnumLang.en ||
      langNavigator === EnumLang.es ||
      langNavigator === EnumLang.oc
    ) {
      return EnumLang[langNavigator];
    } else {
      return EnumLang.ca;
    }
  };