import axios from "axios";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  "Content-Type": "text/plain",
  "Content-Type": "*/*",
};

export const fetchWithBody = async (url, method, body) => {
  const response = await axios.post(url, body);

  return response;
};

export const fetch = async (url, method) => {
  const response = await axios(url, {
    method: method,
    headers: headers,
  });

  return response;
};
